.header {
  padding: 0;

  h1 {
    font-size: 32px;
    font-weight: 600;
    line-height: 40px;
  }
}
.contenar_data{
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 28px;
  background: #FFFFFF;
  box-shadow: 0px 4px 21px rgba(204, 204, 204, 0.25);
  padding: 18px 0;
}
.asdffaffff{
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
}
.main_tesffsffs {
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 28px;
  background: #FFFFFF;
  box-shadow: 0px 4px 21px rgba(204, 204, 204, 0.25);
  padding: 18px 43px;
}
@media only screen and (max-width: 450px) {

.main_tesffsffs{
  padding: 25px 16px;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
}
}