.container_paymentlink {
    padding: 34px 41px;
}

.box_tebalbottom {
    border-radius: 8px;
    background: #FFF;
    margin-top: 34px;
    box-shadow: 0px 2px 14px 2px rgba(174, 174, 174, 0.25);
}

@media only screen and (max-width: 450px) {

    .container_paymentlink {
        padding: 34px 20px;
    }
}