#contenar_data_line {
    padding: 60px;
}

#text_dis_contean {
    // max-width: 500px;
    // margin: auto;
    // margin: auto;
    max-width: 72%;
    text-align: center;
    color: #000;

}

.add_clor_sead {
    color: #025041;
}

#add_new_tewxt_hiding_age {
    color: #343434 !important;

}

#contenar_data_line {
    row-gap: 26px;

}

.heding_tage_key_data {
    color: #2D2C2C;
    font-family: "Poppins", sans-serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 117.647% */
    letter-spacing: 0.34px;
    text-align: center;
    padding: 30px 0px 15px 0px;
    margin-bottom: 0px;
}

.grid_container_list {
    max-width: 94%;
    margin: auto;
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 3px 11px 0px rgba(158, 155, 155, 0.25);

}

.date_formeat_date {
    div {
        border: 0px solid !important;
        color: #2D2C2C !important;
        font-family: "Poppins";
        font-size: 17px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        /* 117.647% */
        letter-spacing: 0.34px;
    }

    span {
        color: #2D2C2C !important;
    }
}

.contean_beles_data {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 22px;
    border-bottom: 1px solid #E2EBE9;
}
.heding_two_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // justify-content: end;
    width: 53%;
}

.porgareshbarline {
    border: 1.5px dashed;
    border-color: #025041;
    border-radius: 40px,
        // border-s
        // border-style: "dashed";
}

.p_tage_cuntean {
    color: #2D2C2C;
    // text-align: right;
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 117.647% */
    letter-spacing: 0.34px
}

.total_heding_data {
    color: #000;
    font-family: Poppins;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-top: 4px;
}

.pay_amount_key_data {
    color: #025041;
    text-align: right;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 117.647% */
    letter-spacing: 0.34px;
}

.display_datathiss {
    display: flex;
    align-items: center;
    width: 50%;
}

.text_p_data_contean {
    color: #282828;
    // text-align: center;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 166.667% */
    letter-spacing: 0.24px;
}

.add_contenar_heding_box {
    display: flex;
    align-items: center;
    padding: 17px 20px 10px 20px;
    width: 100%;
}

.dataloafgaffafdfa {
    color: #282828;
    text-align: center;
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}

.add_contenar_heding_box_two {
    display: flex;
    align-items: center;
    padding: 10px 13px 10px 13px;
    width: 100%;
}

.active_contean_data {
    width: 16.50%;
    display: flex;
    align-items: center;
}

.text_p_data_contean_main_data {
    color: #025041;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 166.667% */
    letter-spacing: 0.24px;
}

.add_chabox_and_p_tage {
    display: flex;
    align-items: center;
    // width: 25%;

    p {
        color: #1D1D1D;
        font-family: Poppins;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        /* 166.667% */
        letter-spacing: 0.24px;
        // padding: 0px 0px 0px 10px;
    }
}

._reapon_accordion {
    margin: 0px 10px;
}

.maiahhshs_adata_logg {
    color: #000;
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px
}

.p_tage_end_text_data {
    color: #1D1D1D;
    text-align: right;
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 200% */
    letter-spacing: 0.2px;
}

.text_tebal_text_dis_data {
    color: #1D1D1D;
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 166.667% */
    letter-spacing: 0.24px;
    // padding-left: 9px;
}

.maiaha_patisan_data {
    width: 50%;
    display: flex;
    justify-content: space-between;
}

.maiaha_patisan_data_two {
    width: 53%;
    display: flex;
    justify-content: space-between;
}

.Box_main_contean_dataa {
    border-radius: 9px;
    border: 1px solid #DDD;
    padding: 21px 10px;
    margin-top: 15px;
}

.main_three_box_in_geap {
    display: flex;

}

.Box_fast_line_text {
    border-radius: 5px;
    border: 1px solid #DDD;
    background: #FFF;
    padding: 14px 45px;
    cursor: pointer;

    p {
        color: #000;
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }

    margin-right: 10px;
}

.baox_helesh_textborder {
    border-radius: 5px;
    border: 1px solid #025041;
    background: #FFF;
    padding: 14px 45px;
    margin-right: 10px;
    cursor: pointer;

    p {
        color: #025041;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }
}

.checkbox_data_fromkey {
    padding: 0px 8px 0px 0px;
}

.heding_data_in_linee {
    padding: 10px 0px;
    font-size: 13px;
}

.button_conteann {
    padding: 30px 0px;
    display: flex;
    justify-content: space-between;
}

.cancel_button_data_textt {
    border-radius: 6px;
    border: 1px solid #6D6B6B;
    color: #000;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    padding: 6px 12px;
    background-color: #FFF;
    /* 166.667% */
}

.cancel_button_data_textt:active {
    background-color: #FFF !important;
    color: #000 !important;
    border: 1px solid #6D6B6B !important;
}

.cancel_button_data_textt:hover {
    background-color: #FFF;
    border: 1px solid #6D6B6B;
}

// .add_yesssshjsjs{

// }
p {
    margin-bottom: 0px;
    font-size: 13px;
}

// .add_yesssshjsjs {
//     height: 48px !important;
//     border-radius: 0px !important;
//     background: #FFFFFF !important;
//     border: none !important;

//     input {
//         height: 48px;
//         border-radius: 50px;
//         background: #FFFFFF !important;
//         font-family: 'Poppins';
//         font-style: normal;
//         font-weight: 400;
//         font-size: 14px;
//         line-height: 20px;
//         letter-spacing: 0.03em;
//         color: #515151;
//         border: 0px solid !important;
//         //  color: white;

//         &::placeholder:hover {
//             color: #515151;
//             color: white;
//         }

//         &::placeholder:focus:hover {
//             color: white;
//         }

//         &::placeholder:hover {
//             color: #ffffff !important;
//         }

//         &:focus::-webkit-input-placeholder,
//         &:hover::-webkit-input-placeholder {
//             color: #ffffff !important;
//         }

//         &:focus {
//             border-color: transparent !important;
//             border: none !important;
//             box-shadow: none !important;

//         }

//         &:focus-visible {
//             border-bottom: none !important;
//             outline: none !important;
//             border-color: transparent;
//         }

//         &:hover {
//             color: white;
//             background-color: #fff !important;
//             // box-shadow: 0px 0px 0px 4px rgba(1,82,65,0.25) !important;
//             // border-color: #015241 !important;
//         }


//     }


// }
.__datepicker {
    display: flex;
    align-items: center;
    height: 48px;
    background: #FFFFFF;
    border: 1px solid #DDDDDD;
    outline: none;
    // font-style: normal;
    // font-weight: 500;
    // font-size: 14px;
    // color: #111111;
    border-radius: 8px;
    padding: 10px;
    margin: 0 0px 0px;
    width: 94%;

    color: #2D2C2C !important;
    font-family: Poppins !important;
    font-size: 12px !important;
    font-style: normal;
    font-weight: 400 !important;
    line-height: 20px !important;
}

.balance .react-datepicker__input-container input:focus {
    box-shadow: none !important;
    border-color: transparent;

}

.dhaHDHdhdddd {
    .react-datepicker__input-container {
        height: 48px;
        border-radius: 50px;
        background: #FFFFFF !important;


    }
}

input {
    &:focus {
        box-shadow: none !important;
        border-color: #015241;
    }
}

.box_dialog_main {
    padding: 14px 32px;
}

.typo_text {
    font-family: 'Poppins' !important;
    font-style: normal;
    font-weight: 500 !important;
    font-size: 16px !important;
    text-align: center;
    color: #282828 !important;
    max-width: 220px;
}

.box_btn_cencal {
    display: flex;
    justify-content: space-between;
    padding-top: 30px;
    max-width: 250px;
    margin: auto;
}

.delete_btn {
    font-family: 'Poppins' !important;
    font-size: 14px !important;
    color: #FF3A23 !important;
    font-weight: 400 !important;
    text-transform: capitalize !important;
    background-color: transparent !important;
    border: none !important;
}

.cancel_btn {
    font-family: 'Poppins' !important;
    font-size: 14px !important;
    color: #025141 !important;
    font-weight: 400 !important;
    text-transform: capitalize !important;
    background-color: transparent !important;
    border: none !important;

}

.menuitem {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #282828;
}

.add_yesssshjsjs {
    color: #2D2C2C !important;
    font-family: Poppins !important;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 600 !important;
    line-height: 20px !important;
}

.add_yesssshjsjs:hover {
    background-color: transparent !important;

    input:hover {
        background-color: transparent !important;
    }
}

// .main_div_list_data>div:nth-child(even) {
//     background: #F9F9F9;
// }

.contean_data_withha {
    width: 36%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 0px 0px 0px;

    p {
        color: #2D2C2C;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }

    svg {
        margin-left: 10px;
    }
}

.selecat_box_callss_data {
    border: 1px solid #DDDDDD;
    border-radius: 100px;
    color: #2D2C2C;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

@media only screen and (max-width: 822px) {
    .contean_beles_data {
        // padding: 10px;
        display: block !important;
    }
.main_div_responss{
    width: 100% !important;
}
    .heding_two_box {
        width: 100%;
        padding: 0px 20px;
    }



}

@media only screen and (max-width: 450px) {
    .text_tebal_text_dis_data{
        width: 90%;

    }
    .maiaha_patisan_data_two {
        width: 100%;
    }

    #text_dis_contean {
        max-width: 100%;
    }

    #contenar_data_line {
        padding: 60px 30px;
        max-height: 367px;


    }

    .calss_data_main_object {
        width: 40% !important;
        padding: 10px 8px 0px 0px !important;
    }

    .maiaha_patisan_data {
        padding: 0px 10px 0px 0px;
        width: 100%;
    }

    .add_chabox_and_p_tage {
        p {
            text-align: end;
        }
    }

    .dataloafgaffafdfa {
        text-align: start;
    }

    .add_contenar_heding_box {
        padding: 17px 8px 10px 8px;
    }

    .main_dese_buttom_data {
        padding: 0px 8px 10px 8px !important;
    }

    .contean_data_withha {
        width: 100%;
        padding: 10px 0px 0px 0px !important;
    }

    .heding_contean_descript {
        display: flex;
        justify-content: space-between;
    }

    .main_div_responss {
        display: block !important;
        width: 100% !important;
    }

    .heding_two_box {
        width: 100%;
        display: block;
        padding: 20px 0px !important;
    }

    .contean_beles_data {
        padding: 10px;
        display: block;
    }

    .grid_container_list {
        max-width: 92%;

    }

    .active_contean_data {
        width: 20%;
    }

    .display_datathiss {
        width: 40%;
    }

    .responsiv_div_data_keyyy {
        display: block !important;
    }

    #from_id_main_link {
        width: 100%;
        margin: auto;
    }

    .main_three_box_in_geap {
        display: block;
    }

    .Box_fast_line_text {
        margin-bottom: 10px;
    }

    .baox_helesh_textborder {
        margin-bottom: 10px;
    }

    .button_conteann {
        display: block;
    }

    .seve_and_create_button {
        display: flex;
        padding-top: 13px;

    }
}