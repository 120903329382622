.contenar_datafooterline {
    border-radius: 12px;
    background: var(--theme-neutrals-100-white, #FFF);
    box-shadow: 0px 0px 13px 0px rgba(17, 17, 17, 0.10);
    margin: 40px 120px;
    padding: 20px 28px;

    .text_heding_data_centerr {
        color: #3A3A3A;
        text-align: center;
        font-family: Poppins;
        font-size: 22px;
        font-style: normal;
        font-weight: 600;
        line-height: 40px;
        padding-bottom: 20px;
    }

    .main_contenar_lineene_border {
        border-radius: 8px;
        border: 1px solid var(--theme-neutrals-80-light-grey-2, #DDD);
        background: var(--theme-neutrals-100-white, #FFF);
        padding: 10px 19px;

        .AccordionSummary_calll_list {
            border-bottom: 1px solid var(--theme-neutrals-80-light-grey-2, #DDD);
            padding: 10px 8px;
            min-height: 0px;

            .div_main_contean_llooo {
                display: flex;
                // justify-content: space-between;
                width: 100%;

                .text_heding_clorrrrr {
                    overflow: hidden;
                    color: #025041;
                    text-overflow: ellipsis;
                    font-family: Poppins;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    width: 48%;
                    line-height: 20px;
                }

                .hedingg_center_lineejee {
                    color: var(--theme-neutrals-10-light-black, #111);
                    text-align: center;
                    font-family: Poppins;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px;
                    /* 200% */
                }

            }

        }

        .maianahahgs_accodinggg {
            padding: 0px;

            .main_div_footer_accrdion {
                border-bottom: 1px solid #DDD;
                padding: 10px 10px 7px 10px;

                .p_number_listtt_contean {
                    overflow: hidden;
                    color: #000;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px;
                }

                .text_benifisal_datatta {
                    color: #000;
                    font-family: Poppins;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 20px;
                    padding-bottom: 14px;
                }

                .main_contean_lineeeee {
                    display: flex;
                    justify-content: space-between;
                    padding: 5px 0px;

                    .p_number_listtt_contean {
                        overflow: hidden;
                        color: #000;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 20px;
                    }

                    .bload_clor_text_filddd {
                        color: var(--theme-neutrals-10-light-black, #111);
                        text-align: right;
                        font-family: Poppins;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 20px;
                    }
                }

            }
        }
    }

    .main_contenar_lineene_border_twoooo {
        border-radius: 8px;
        border: 1px solid var(--theme-neutrals-80-light-grey-2, #DDD);
        background: var(--theme-neutrals-100-white, #FFF);
        padding: 21.50px;
        margin-top: 35px;

        .mainn_contenar_datataaa {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .main_div_ptageee {
                width: 30%;
                display: flex;
                justify-content: space-between;

                .main_contean_list_ptagee {
                    overflow: hidden;
                    color: #111;
                    text-overflow: ellipsis;
                    font-family: Poppins;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 20px;
                }

                .amountt_text_shhshg {
                    overflow: hidden;
                    color: #111;
                    text-align: right;
                    text-overflow: ellipsis;
                    font-family: Poppins;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px;
                }
            }
        }
    }
}

.white_btn {
    border: 1px solid #025041 !important;
    background-color: white !important;
    color: #025141 !important;
    margin-right: 10px;

}

.save_btn {
    border: 1px solid #025041 !important;
    background-color: #025141 !important;
    color: #FFFFFF !important;
    padding: 12px 30px !important;
    margin-right: 10px;
}

@media only screen and (max-width: 450px) {
.contean_paybill{
    width: 100% !important;
}
#id_amount{
    width: 35% !important;
}
.mainn_contenar_datataaa{
    display: block !important;
}
.main_div_ptageee{
    width: 100% !important;
}
    .contenar_datafooterline {
        margin: 40px 12px;
        padding: 20px 12px;

        .main_contenar_lineene_border {
            padding: 10px 10px;
        }

        .main_contenar_lineene_border_twoooo {
            padding: 21.50px 10px;
            .mainn_contenar_datataaa {
              

                .main_div_ptageee {
                    width: 46%;
                }
            }
        }
    }
}