.trans-modal .trans-detail-container {
    background: #FFFFFF;
    border-radius: 8px;
    padding: 32px;
    width: 440px;
    margin: 0 auto;

    &__heading {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        text-align: center;
        letter-spacing: 0.02em;
        color: #282828;
        margin-bottom: 34px;
    }

    .pdf-show {
        display: block;
    }

    .pdf-hide {
        display: none;
    }

    &__table {
        border: 1px solid #DDDDDD;
        border-radius: 8px;
        padding: 0;
        margin-bottom: 18px;

        .bottomNone {
            border-bottom: none !important;
        }

        .b-bot {
            border-bottom: 1px solid #DDDDDD;
        }

        .dflex {
            display: grid;
            grid-template-columns: 1fr 1fr;
            // margin-bottom: 10px;
            align-items: center;
            height: auto;
            padding: 7px 15px;

            .left {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
                color: #798194;
            }

            .right {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 500;
                font-size: 13px;
                line-height: 24px;
                letter-spacing: -0.01em;
                color: #202D4C;
                text-overflow: unset;
                overflow: visible;
                white-space: inherit;
                // text-overflow: ellipsis;
                // overflow: hidden;
                // white-space: nowrap;
            }
        }
    }

    &__btn-row {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;

        .btn {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 24px;
            letter-spacing: 0.05em;
            color: #FFFFFF;
            border-radius: 6px;

            &:disabled {
                background: #CFCFCF !important;
            }
        }

        &__green {
            background: #025041 !important;
            color: #FFFFFF !important;
            border-color: #CFCFCF !important;
            padding: 11px 40px;
            width: 380px;
        }
    }
}

.trans-modal .modal-content {
    height: auto;
}

.wp_daya_cloar_dafaa {
    background-color: #025041;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        color: #fff;
        font-size: 24px;
    }
}

.download_buttoun {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    cursor: pointer;
}

#adaDdADddd{
    cursor: pointer;
// pointer-events: painted;
}
@media only screen and (max-width: 450px) {

    .trans-modal .trans-detail-container {

        width: 100%;
    }
}