.listtexterrr {
  color: red;
  padding-top: 10px;
}

.adhadhjdjjdhuduhdh {
  background-color: transparent !important;
  border: none !important;
  color: #025041 !important;
  padding: 0px !important;
}

.flax_data_list {
  display: flex;
  // position: relative;
}

.aDADADaDsfgf {
  position: absolute;
  left: 91%;
  /* left: 10px; */
  margin: 25px 0px 0px 0px;
  cursor: pointer;
  // bottom: 10%;
}

.upload-icon {
  position: absolute;
  left: 91%;
  top: 25px;
  /* left: 10px; */
  margin: 25px 0px 0px 0px;
  cursor: pointer;
}

#data_disebal {
  cursor: no-drop;
  background-color: #cfcfcf;
}

.company-details-container2 {
  display: flex;
  justify-content: center;
  background: #ffffff;
  box-shadow: 0px 3px 11px rgba(158, 155, 155, 0.25);
  border-radius: 10px;
  width: 585px;
  padding: 20px 0px;
  margin: 0 auto;

  &__wrapper {
    .image-wrapper {
      text-align: center;
    }

    .input-form-wrapper {
      margin: 20px 0px;

      label {
        display: block;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        // color: #515151;
        color: #000 !important;
        /* added by  by VL 9 mar NIO-25 */
        margin-bottom: 5px;
      }

      .text-field {
        border: 1px solid #dddddd;
        border-radius: 5px;
        width: 368px;
        height: 48px;
        padding: 0px 8px;
        outline: none;
        font-weight: 400;
        font-size: 12px;
        line-height: 24px;
        color: #3a3a3a;
      }
    }
  }

  &__heading2 {
    padding-bottom: 30px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
    letter-spacing: 0.1em;
    text-align: center;
    // margin-bottom: 58px;
  }
}

.form-control:focus {
  border-color: #015241 !important;
  box-shadow: 0px 0px 0px 4px rgba(1, 82, 65, 0.25) !important;
}

.inputBx2 select {
  height: 34px;
}

.inputBx3 select {
  height: 36px;
}

.company-details-container {
  display: flex;
  justify-content: center;
  background: #ffffff;
  box-shadow: 0px 3px 11px rgba(158, 155, 155, 0.25);
  border-radius: 10px;
  width: 585px;
  margin: 0 auto;

  &__wrapper {
    .image-wrapper {
      text-align: center;
    }

    .inputBx {
      margin: 20px 0px;

      input {
        height: 48px;
      }

      select {
        height: 48px;
      }
    }

    .cursorPoint {
      input {
        cursor: not-allowed !important;
      }
    }

    .input-form-wrapper {
      margin: 10px 0px;

      label {
        display: block;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        // color: #515151;
        color: #000 !important;
        /* added by  by VL 9 mar NIO-25 */
        margin-bottom: 5px;
      }

      .text-field {
        border: 1px solid #dddddd;
        border-radius: 5px;
        width: 100%;
        height: 48px;
        padding: 0px 8px;
        outline: none;
        font-weight: 400;
        font-size: 12px;
        line-height: 24px;
        color: #3a3a3a;
      }

      .border-green:hover {
        // border: 1px solid #025041 !important;
        color: #111111;
        background-color: #ffffff;
        border-color: #025041 !important;
        box-shadow: 0px 0px 0px 4px #02504133;
      }

      .readonly {
        color: #515151 !important;
      }

      select {
        border: 1px solid #dddddd;
        border-radius: 5px;
        width: 100%;
        height: 48px;
        outline: none;
        padding: 0px 8px;
        color: #3a3a3a;
      }
    }
  }
}

.upload-label {
  display: block;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  // color: #515151;
  color: #000 !important;
  /* added by  by VL 9 mar NIO-25 */
  margin-bottom: 5px;
}

// User Detail Section

.user-detail-container {
  width: 585px;
  background: #ffffff;
  box-shadow: 0px 3px 11px rgba(158, 155, 155, 0.25);
  border-radius: 10px;
  margin: 0 auto;

  &__wrapper {
    padding: 27px;

    .first-last-name {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 20px;
    }

    .m-10 {
      margin: 0px !important;
    }

    .input-form-wrapper {
      margin: 20px 0px;
      position: relative;

      label {
        display: block;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        // color: #515151;
        color: #000 !important;
        /* added by  by VL 9 mar NIO-25 */
        margin-bottom: 5px;
      }

      .field-enable {
        background: #ffffff !important;
      }

      .text-field {
        border: 1px solid #dddddd;
        border-radius: 5px;
        width: 100%;
        height: 48px;
        padding: 0px 8px;
        outline: none;
        font-weight: 400;
        font-size: 12px;
        line-height: 24px;
        // color: #3A3A3A;
        color: #212529;
        background-image: none;
      }

      .border-green {
        border: 1px solid #dddddd !important;
      }

      .readonly {
        color: #515151;
      }

      .viewbbtn {
        position: absolute;
        right: -10px;
        top: 6px;
        color: #b8b8b8;
      }

      .viewbbtn:hover,
      .viewbbtn:focus {
        background: none;
      }

      .helptext {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 20px;
        /* or 200% */

        letter-spacing: 0.07em;

        color: #878787;
      }

      select {
        border: 1px solid #dddddd;
        border-radius: 5px;
        width: 100%;
        height: 48px;
        outline: none;
        padding: 0px 8px;
        color: #3a3a3a;
      }
    }
  }
}

//account management
.accoun-management {
  &__header {
    /* comment by VJ - feedback 13/2 */
    // height: 68px;
    // background: #FFFFFF;
    // box-shadow: 0px 4px 21px rgba(204, 204, 204, 0.25);
    // padding: 24px 49px;
    display: flex;
    align-items: center;
    display: flex;
    align-items: center;
    background: #ffffff;
    box-shadow: 0px 4px 21px #00000025;
    padding: 24px 58px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    color: #025041;

    &__item {
      margin-right: 28px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 30px;
      letter-spacing: 0.1em;
      color: #000;
      cursor: pointer;
      letter-spacing: 0;
      color: #025041;
      border-bottom: 2px solid transparent;
    }
  }

  .red {
    color: red !important;
  }

  .active {
    font-weight: 700;
    border-bottom: 2px solid;
    // color: #d3eee8;
  }

  &__content {
    padding: 28px;
  }
}

// user management
.users {
  background: #ffffff;
  box-shadow: 0px 3px 11px rgba(158, 155, 155, 0.25);
  border-radius: 10px;
  margin: 0 auto;
  width: 585px;
  padding: 34px 107px;

  &__heading {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
    letter-spacing: 0.1em;
    text-align: center;
    margin-bottom: 58px;
  }

  &__user {
    text-align: center;

    img,
    svg {
      margin-bottom: 15px;
    }

    img {
      width: 88px;
    }

    &__name {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      letter-spacing: 0.03em;
      color: #000;
    }

    &__desig {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 8px;
      line-height: 20px;
      letter-spacing: 0.05em;
      color: #025041;
    }
  }

  &__list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 30px;
  }

  &__btn-row {
    margin-top: 75px;
    display: flex;
    justify-content: center;

    .btn {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #ffffff;
      height: 48px;
      padding: 6px 25px;
      border-radius: 6px;
      width: 100%;
      // background-color: #015241;
    }
  }

  &__save-btn {
    border: 1px solid #025041 !important;
    background-color: #025141 !important;
    color: #ffffff !important;
    margin-left: 10px;
  }
}

//plan billing
.plan-billing {
  padding: 47px 34px;

  &__upper-card {
    background: #ffffff;
    box-shadow: 0px 2px 11px rgba(145, 145, 145, 0.25);
    border-radius: 10px;
    padding-top: 24px;
    max-width: 990px;

    &__row1 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 24px;

      &__name {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 20px;
        letter-spacing: 0.1em;
        color: #282828;
      }

      &__active-tag {
        background: #025041;
        border-radius: 20px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 20px;
        letter-spacing: 0.1em;
        color: #ffffff;
        padding: 5px 17px;
      }
    }

    &__row2 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 41px;
      margin-bottom: 28px;
      padding: 0 24px;

      &__free {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
        line-height: 20px;
        letter-spacing: 0.06em;
        color: #282828;
      }
    }

    &__row3 {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
      padding: 0 24px;
      padding-bottom: 27px;
      border-bottom: 1px solid #dddddd;

      &__user-count {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        letter-spacing: 0.1em;
        color: #515151;
      }

      &__acc-id {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 20px;
        text-align: right;
        letter-spacing: 0.1em;
        color: #515151;
      }
    }

    &__bot {
      display: grid;
      grid-template-columns: 1fr 1fr;
      height: 121px;

      &__left {
        border-right: 1px solid #dddddd;
        padding: 11px 22px;
      }

      &__right {
        padding: 11px 22px;
      }

      &__img {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-bottom: 16px;
        height: 29px;
      }

      &__count {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 700;
        font-size: 28px;
        line-height: 20px;
        text-align: right;
        letter-spacing: 0.1em;
        color: #282828;
        text-align: start;
        margin-bottom: 9px;
      }

      &__type {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        letter-spacing: 0.1em;
        color: #515151;
        text-align: start;
      }
    }
  }

  &__allplans {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 34px;
    margin-bottom: 38px;

    &__txt {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 20px;
      letter-spacing: 0.09em;
      color: #282828;
    }

    label {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
      letter-spacing: 0.09em;
      color: #515151;
    }

    .form-check-input:checked {
      border-color: #025041;
      background-color: #025041;
    }
  }

  &__plan {
    padding: 22px;
    background: #ffffff;
    box-shadow: 0px 2px 11px rgba(145, 145, 145, 0.25);
    border-radius: 10px;
    height: 573px;

    &__name {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 700;
      font-size: 15px;
      line-height: 20px;
      letter-spacing: 0.1em;
      color: #464646;
      margin-bottom: 14px;
    }

    &__txt {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
      letter-spacing: 0.08em;
      color: #515151;
    }

    &__free {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 20px;
      letter-spacing: 0.06em;
      color: #282828;
      margin: 44px 0;
    }

    &__heading {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
      letter-spacing: 0.05em;
      color: #282828;
      margin-bottom: 9px;
    }

    &__list {
      display: grid;
      row-gap: 2px;
      margin-bottom: 34px;

      &__item {
        display: flex;
        align-items: center;
        column-gap: 8px;

        span {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 20px;
          display: flex;
          align-items: center;
          letter-spacing: 0.08em;
          color: #515151;
        }
      }
    }

    &__selectedtxt {
      margin-top: 64px;
      text-align: center;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
      letter-spacing: 0.1em;
      color: #282828;
    }

    &__pricing {
      display: grid;
      margin-top: 44px;
      margin-bottom: 24px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.06em;
      color: #282828;

      &__amount {
        font-weight: 700 !important;
        font-size: 24px !important;
        line-height: 20px !important;
        color: #282828 !important;
        margin-right: 10px;
      }

      span {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 20px;
        letter-spacing: 0.06em;
        color: #000;
      }
    }
  }

  &__planlist {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 26px;
  }

  .btn {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    height: 40px;
    width: 100%;
    padding: 10px;
    border-radius: 6px;
  }

  &__white-btn {
    border: 1px solid #025041 !important;
    background-color: white !important;
    color: #025141 !important;
    margin-top: 20px !important;

    &:hover {
      background-color: #025041 !important;
      color: #ffffff !important;
    }
  }

  &__allfeatures {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.1em;
    text-decoration-line: underline;
    color: #025041;
    margin: 35px 0;
    display: flex;
    justify-content: center;
    cursor: pointer;
  }

  &__billinghistory {
    margin-top: 59px;

    &__heading {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 20px;
      letter-spacing: 0.09em;
      color: #282828;
      margin-bottom: 22px;
    }

    &__row {
      display: grid;
      grid-template-columns: 1fr 2fr 2fr 1fr;
      align-items: center;
      padding: 0 22px;
      height: 45px;
      background: #f7f7f7;
      border-radius: 5px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
      letter-spacing: 0.08em;
      color: #515151;

      &__download {
        display: flex;
        align-items: center;
        column-gap: 10px;
        cursor: pointer;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        letter-spacing: 0.08em;
        color: #025041;
      }
    }
  }
}

//profile

.profile {
  display: flex;
  align-items: center;
  justify-items: center;
  height: 100vh;
}

.complete-profile {
  background: #ffffff;
  box-shadow: 0px 3px 11px rgba(158, 155, 155, 0.25);
  border-radius: 10px;
  width: 580px;
  margin: 10px auto;
  padding: 40px 86px;

  &__heading {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
    color: #2d2c2c;
    text-align: center;
    // margin-bottom: 48px;
  }

  &__businesstypo {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #025041;
    padding-top: 10px;
  }

  &__ADDBTNHHA {
    background-color: transparent !important;
    color: #025041 !important;
    border: 0px solid !important;
    padding: 0px !important;
  }

  &__removebtn {
    background-color: transparent !important;
    color: #f87161 !important;
    border: 0px solid !important;
    padding: 0px 0px 10px 0px !important;
  }

  &__saventmm {
    background-color: #fff !important;
    border-color: #015241 !important;
    color: #000 !important;
    padding: 11px 60px !important;
    border-radius: 6px !important;
    color: #fff;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.05em;
    line-height: 24px;
    margin-right: 20px;
  }

  &__boxbtndisplay {
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
  }

  &__SELECT {
    margin-top: 40px !important;
    border-color: #dddddd !important;
  }

  &__SELECT:hover {
    margin-top: 40px !important;
    background-color: #ffffff;
    border-color: #025041;
    box-shadow: 0px 0px 0px 4px #02504133;
  }

  &__submitbtnlist {
    background: #025041 !important;
    color: #fff !important;
    padding: 11px 60px !important;
    border: none !important;
    color: #fff;
    flex: 1;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.05em;
    line-height: 24px;
    text-transform: capitalize !important;
  }

  &__submitbtnlist:focus-visible {
    border: 0px solid !important;
    outline: 0px solid !important;
  }

  &__btn_box {
    display: flex;
    justify-content: space-between;
  }

  &__director {
    color: #515151;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
  }

  &__upload {
    // border: 1px solid #dddddd;
    // border-radius: 5px;
    // height: 48px;
    margin-bottom: 15px;
    width: 100%;

    input[type="file"] {
      display: none;
    }

    &__label {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: 1px solid #dddddd;
      border-radius: 5px;
      padding: 0.95rem 0.75rem; //commnent by vaishali feedbback 13/2 no 86
      cursor: pointer;
      text-decoration: none !important;
    }

    &__label:hover {
      background-color: #ffffff;
      border-color: #025041 !important;
      box-shadow: 0px 0px 0px 4px #02504133;
    }

    &__txt {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
      // letter-spacing: 0.05em; commnent by vaishali feedbback 13/2 no 86
      color: #515151;
      max-width: 95%;
      word-wrap: break-word;
      text-decoration: none !important;
    }
  }

  &__goback {
    background-color: #fff !important;
    border-color: #015241 !important;
    border-radius: 6px !important;
    color: #015241 !important;
    font-family: Poppins !important;
    font-size: 15px !important;
    font-style: normal;
    font-weight: 400 !important;
    letter-spacing: 0.05em !important;
    flex: 1;
    line-height: 24px !important;
    margin-right: 20px !important;
    padding: 11px 60px !important;
    border: 1px solid !important;
  }

  &__btn {
    background: #025041 !important;
    border-color: #025041 !important;
    height: 48px;
    border-radius: 6px !important;
    font-family: "Poppins" !important;
    font-style: normal;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color: #ffffff !important;
    padding: 12px !important;
    width: 100%;
    margin-top: 50px;
    margin-bottom: 27px;
  }

  &__btn2 {
    background: #025041 !important;
    border-color: #025041 !important;
    height: 48px;
    border-radius: 6px !important;
    font-family: "Poppins" !important;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px !important;
    color: #ffffff !important;
    padding: 12px !important;
    width: 100%;
    margin-top: 30px;
    margin-bottom: 27px;
  }
}

.complete-profile2 {
  background: #ffffff;
  box-shadow: 0px 3px 11px rgba(158, 155, 155, 0.25);
  border-radius: 10px;
  width: 580px;
  margin: 10px auto;
  padding: 40px 90px;

  &__heading {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
    letter-spacing: 0.1em;
    color: #2d2c2c;
    text-align: center;
    margin-bottom: 48px;
  }

  &__upload {
    // border: 1px solid #B8B8B8;
    // border-radius: 5px;
    height: 48px;
    margin-bottom: 23px;
    width: 100%;

    input[type="file"] {
      display: none;
    }

    &__label {
      border: 1px solid #b8b8b8;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.95rem 0.75rem; //commnent by vaishali feedbback 13/2 no 86
      cursor: pointer;
    }

    &__txt {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
      // letter-spacing: 0.05em; commnent by vaishali feedbback 13/2 no 86
      color: #515151;
    }
  }

  &__btn {
    background: #b8b8b8 !important;
    border-color: #b8b8b8 !important;
    height: 48px;
    border-radius: 6px !important;
    font-family: "Poppins" !important;
    font-style: normal;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color: #ffffff !important;
    padding: 12px !important;
    width: 100%;
    margin-top: 19px;
    margin-bottom: 27px;
  }
}

.btndisable {
  background: rgba(2, 80, 65, 0.5) !important;
}

.document-submitted {
  width: 580px;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(117, 114, 114, 0.25);
  border-radius: 8px;
  padding: 48px 64px;
  margin: 10px auto;
  text-align: center;

  &__heading {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 40px;
    text-align: center;
    color: #000;
    margin-bottom: 12px;
  }

  &__desc {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.05em;
    color: #000;

    span {
      color: #4d4d4d;
      font-weight: 500;
    }
  }

  &__btn {
    background: #ffffff !important;
    border-color: #6d6b6b !important;
    height: 48px;
    border-radius: 6px !important;
    font-family: "Poppins" !important;
    font-style: normal;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color: #000 !important;
    padding: 12px !important;
    margin-top: 41px;
    margin-bottom: 12px;
    width: 166px;
  }
}

.back_btn {
  height: 20px;
  width: 30px;
  background-color: transparent !important;
  padding: 5px;
  border: none;
  font-weight: 500;
  margin-bottom: 15px;
}

.document-verified {
  width: 580px;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(117, 114, 114, 0.25);
  border-radius: 8px;
  padding: 48px 64px;
  margin: 10px auto;
  text-align: center;

  &__heading {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 40px;
    text-align: center;
    color: #025041;
    margin-bottom: 12px;
  }

  &__desc {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.05em;
    color: #000;

    span {
      color: #4d4d4d;
      font-weight: 500;
    }
  }

  &__btn {
    background: #ffffff !important;
    border-color: #6d6b6b !important;
    height: 48px;
    border-radius: 6px !important;
    font-family: "Poppins" !important;
    font-style: normal;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color: #000 !important;
    padding: 12px !important;
    margin-top: 41px;
    margin-bottom: 12px;
    width: 166px;
  }
}

.react-tel-input .form-control {
  height: 48px;
  width: 100%;
}

.gap-10 {
  gap: 10px;
}

.primarybtn {
  background: #025041 !important;
  color: #ffffff !important;
  padding: 11px 40px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.05em;
  color: #ffffff;
  border-radius: 6px;
}

.updatepasstext {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.03em;
  color: #3a3a3a;
}

.input-form-wrapper {
  input {
    width: 100%;
    border: 1px solid #dddddd;
    border-radius: 8px;
    height: 48px;
  }

  &__dot {
    label:after {
      content: "";
      display: inline-block;
      margin-left: 7px;
      vertical-align: middle;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: rgb(255, 71, 93);
    }
  }
}

.update-password {
  padding: 40px 30px !important;
}

#hveeinrueu:hover {
  color: #111111;
  background-color: #ffffff;
  border-color: #025041 !important;
  box-shadow: 0px 0px 0px 4px #02504133;
}

#disebal {
  cursor: no-drop;
}

.addnewpere {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: red;
  padding: 5px 0px 0px 0px;
  margin: 0;
}

.required_marker {
  margin-left: 7px;
  vertical-align: middle;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: rgb(255, 71, 93);
}

.addinvitetext {
  font-family: "Poppins";
  font-size: 25px;
  color: #3a3a3a;
  text-align: center;
}

#updet_apss_change {
  margin-top: 30px;
  margin-bottom: 27px;
}

#whith_butttlalla {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

@media only screen and (max-width: 450px) {
  .accoun-management__header {
    padding: 24px 10px;
  }

  .user-detail-container {
    width: 100% !important;
  }

  .document-verified {
    width: 92% !important;
    padding: 24px 12px !important;
    // margin: 0px 10px;
  }

  #asdaDsdDDddddd {
    padding: 10px 15px;
  }

  #btuutton_list_updet_pass {
    display: block !important;
  }

  #button_go_back_list {
    width: 100%;
  }

  #butto_two_data_submitt {
    width: 100%;
    margin-top: 10px;
  }

  #button_three_updet_pass {
    width: 100%;
    margin-top: 10px;
  }

  .aFaFaFfwfffff {
    width: 84%;
    margin: auto;
  }

  .update-password {
    padding: 40px 0px !important;
  }

  #updet_apss_change {
    display: block !important;
  }

  .btuuon_contenar_list {
    width: 84% !important;
    margin: auto;
    padding-top: 10px;
  }

  #whith_butttlalla {
    width: 100%;
  }

  .company-details-container {
    width: 92%;
    padding: 10px 15px;
  }

  .complete-profile {
    width: 100%;
    padding: 10px 15px;
  }

  .complete-profile__upload__label {
    width: 100% !important;
  }
}

.tags {
  &__header {
    background: #ffffff;
    box-shadow: 0px 4px 21px rgba(204, 204, 204, 0.25);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 18px 43px;

    &__name {
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 20px;
      font-family: "Poppins";
      color: #025041;
    }
  }

  .btn {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    padding: 12px 16px;
    border-radius: 6px;
  }

  &__white-btn {
    border: 1px solid #025041 !important;
    background-color: white !important;
    color: #025141 !important;
  }

  &__save-btn {
    border: 1px solid #025041 !important;
    background-color: #025141 !important;
    color: #ffffff !important;
    margin-right: 43px;
  }

  h1 {
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    color: #025041;
    font-family: "Poppins";
    margin-left: 15px;
    padding-left: 43px;
  }
}

.create_intigretion {
  width: 100%;
  max-height: 250px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 3px 11px 0px rgba(158, 155, 155, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 17px;

  .create_intigretion_btn {
    color: #fff !important;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    display: flex;
    padding: 6px 25px;
    border-radius: 6px;
    background: #025141 !important;
    border: 0px;
  }

  .heading_create {
    color: #2d2c2c;
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.44px;
    margin: 0px;
  }

  .description_create {
    font-size: 13px;
    font-family: Poppins;
    font-weight: 400;
    margin: 0px;
  }

  .list_heading_create {
    color: #2d2c2c;
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.44px;
    margin-bottom: 40px;
  }
}

.list_div_intigretion {
  justify-content: center;
  align-items: center;
  gap: 40px;
  flex-wrap: wrap;
  display: flex;
}

.box_intigretion {
  display: flex;
  width: 280px;
  padding: 30px 14px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 9px;
  background: rgb(255, 255, 255);
  box-shadow: rgba(17, 17, 17, 0.17) 0px 1px 7px 0px;
}

.create_rows {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 35px;

  .row_text {
    color: #000;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    margin: 0px;
  }

  .button_api_credential {
    color: #025041;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    padding: 10px 80px;
    border-radius: 9px;
    border: 1px solid #025041;
    background-color: #ffffff;
  }

  .icon_button_api_credential {
    border-radius: 9px;
    border: 1px solid #6d6b6b;
    background-color: #ffffff;
    padding: 9px;
  }
}

.create_api_box {
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  height: 73vh;

  .inside_sub_box {
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 3px 11px 0px rgba(158, 155, 155, 0.25);
    padding: 60px 100px;
    width: 100%;
    display: flex;
    max-width: 580px;
    flex-direction: column;
    justify-content: center;

    .heading_create_api {
      color: #2d2c2c;
      font-family: Poppins;
      font-size: 17px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      text-align: center;
      letter-spacing: 0.34px;
      margin: 0px;
      margin-bottom: 30px;
    }

    .input_box {
      width: 100%;
      display: flex;
      flex-direction: column;
      row-gap: 8px;

      .input_lable {
        color: #515151;
        font-family: Poppins;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        margin: 0px;
        letter-spacing: 0.12px;
      }

      .input_box {
        display: flex;
        width: 100%;
        max-width: 400px;
        height: 48px;
        padding: 14px 23px;
        border-radius: 5px;
        border: 1px solid #b8b8b8;
        font-size: 11px;
      }
    }
  }
}

.DGDGGDGGDBJXJXBX {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.sasjjshshhshhs {
  border: 1px solid #025041 !important;
  background-color: #025141 !important;
  color: #ffffff !important;
  text-transform: capitalize;
  // margin-right: 43px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  padding: 12px 16px;
  border-radius: 6px;
}

@media only screen and (max-width: 450px) {
  .calss_tage_ajajahhahha {
    padding: 10px 13px !important;
    width: 100%;
    overflow-x: auto;
  }

  .create_api_box {

    .inside_sub_box {
      padding: 60px 26px;
    }
  }

  .description_create {
    padding: 0px 15px;
    text-align: center;
  }

  .tags {
    h1 {
      padding-left: 0px;
    }

    &__save-btn {
      margin-right: 0px;
    }
  }
}

@media only screen and (max-width: 900px) {
  .create_api_box {
    height: 50vh;

    .inside_sub_box {
      padding: 60px 26px;
      // .classNameBox{
      //   padding: 60px 60px;
      // }
    }
  }

  .complete-profile {
    width: 100%;
  }
}