body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --bs-link-color: #055041 !important;
  --bs-link-hover-color: #2b7a6a !important;
  --bs-btn-bg: #2b7a6a !important;
  --bs-btn-hover-color: green !important
}

.btn-primary {
  --bs-btn-hover-color: #000 !important
}

.rc-table-body td.rc-table-cell,
.rc-table-body td.rc-table-cell * {
  font-family: 'Poppins';
  font-size: 13px;
  font-weight: 400;
  /* color: #6D6B6B; */
  color: #000;
  /* added by  by VL 9 mar NIO-25 */

}

.rc-table-header tr th {
  font-family: 'Poppins';
  font-size: 13px;
  font-weight: 500;
  color: #000;
}

.rc-table .rc-table-body tr:not(.rc-table-placeholder):hover {
  background-color: #fafafa !important;
}

.multiSelectContainer li:hover {
  background: #F3F3F3 !important;
  color: #111111 !important;
}

.highlightOption {
  /* background: gray!important; */
  color: #fff;
}

td.rc-table-cell span {
  background: transparent;
}

.rc-table {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 14px 2px hsl(0deg 0% 68% / 25%);
  padding: 6px;
  width: 100%;
}

.rc-table td.rc-table-cell {
  padding: 14px 30px 14px 8px;
}

option:hover {
  background-color: gray;
}

form svg {
  vertical-align: inherit;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #015241 !important;
  border: 1px solid #015241;
  border-width: 1px solid !important;
}

.react-datepicker__month-text {

  width: 100% !important;

}

.scroll_remove .rc-table-body {
  overflow-y: visible !important;
} 
 @media print {
 
  html,
  body {

      /* Hide the whole page */
      display: none;
  }
}