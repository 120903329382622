.bank-acc-detail {
    background: #FFFFFF;
    border-radius: 8px;
    padding: 21px 32px;
    width: 635px;
    margin: 0 auto;
    
    .text-field {
        background: white;
    }

    &__uprow {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 24px;
        color: #000;
        cursor: pointer;
        width: fit-content;
        margin-bottom: 35px;

        svg {
            margin-right: 14px;
        }
    }

    &__logo {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 12px;
    }

    &__heading {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        text-align: center;
        color: #111111;
    }

    &__bname {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.06em;
        color: #000;
    }

    &__desc {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #000;
        margin-top: 27px;
        margin-bottom: 33px;
    }

    &__mb-24 {
        margin-bottom: 24px;
    }

    label {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        color: #202D4C;
        margin-bottom: 4px;
    }

    .doc-form-input {
        outline: none;
        padding: 0px 12px;
        height: 40px;
        background: #FFFFFF;
        outline: none;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #111111;
        border-radius: 5px;
        border: 1px solid #E9EAED;

        &::placeholder {
            color: #000;
            font-weight: 400;
        }
    }

    &__btn-row {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 56px;
        margin-bottom: 40px;

        .btn {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.05em;
            color: #FFFFFF;
            border-radius: 6px;
        }

        &__grey {
            background: #025041 !important;
            color: #FFFFFF !important;
            border-color: #F3F3F3 !important;
            padding: 11px 40px;
            width: 100%;
        }
    }
}
@media only screen and (max-width: 450px) {
    .bank-acc-detail {
        padding: 20px;
        width: 100%;
    }

}