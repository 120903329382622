.pay-initiated-container {
    background: #FFFFFF;
    border-radius: 8px;
    padding: 32px;
    width: 440px;
    margin: 25px auto;
    box-shadow: 0 3px 11px hsla(0,2%,61%,.25);
    &__logo {
        display: flex;
        justify-content: center;
        margin-bottom: 15px;
    }

    &__heading {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        text-align: center;
        letter-spacing: 0.02em;
        color: #257D6B;
        margin-bottom: 20px;
    }

    &__desc {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.03em;
        color: #000;
        margin-bottom: 34px;
    }

    &__table {
        border: 1px solid #DDDDDD;
        border-radius: 8px;
        padding: 0 12px;
        margin-bottom: 18px;

        .bottomNone {
            border-bottom: none !important;
        }

        .b-bot {
            border-bottom: 1px solid #DDDDDD;
        }

        .dflex {
            display: grid;
            grid-template-columns: 1fr 1fr;
            // margin-bottom: 10px;
            align-items: center;
            // height: 36px;
            padding: 5px 8px;

            .left {
                font-weight: 400;
                font-size: 13px;
                line-height: 30px;
                color: #000;
            }

            .right {
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: #111111;
                // text-overflow: ellipsis;
                // overflow: hidden;
                // white-space: nowrap;
                padding-right: 15px;
            }
        }
    }

    &__btn-row {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 65px;
        gap: 10px;
        .btn {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 24px;
            letter-spacing: 0.05em;
            color: #FFFFFF;
            border-radius: 6px;

            &:disabled {
                background: #CFCFCF !important;
            }
        }

        &__green {
            background: #025041 !important;
            color: #FFFFFF !important;
            border-color: #CFCFCF !important;
            padding: 11px 40px;
            // width: 380px;
        }
    }
}
@media only screen and (max-width: 450px) {
    .pay-initiated-container{
        padding: 20px;
        width: 100%;
    }
}