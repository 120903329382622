.contean_grid {
    box-shadow: 0px 7px 16px 0px #53525214;
    margin-top: 40px;

    .heading_text {
        padding: 16px 32px;
        font-family: Poppins;
        font-size: 14px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: left;
        color: #1D1D1D;
        border-bottom: 1px solid #DDDDDD;

    }

    .grid_currency_box {
        padding: 16px 22px;

        .grid_button_contean {
            padding: 6px 6px;

            .box_currency {
                background: #F2F8F7;
                border-radius: 8px;
                width: 100%;
                height: 100%;
                border: 0px solid;

                // margin: 10px;
                .currency_img {
                    width: 39px;
                    height: 28px;

                    img {
                        border-radius: 50%;
                        width: 100%;
                        height: 100%;

                    }
                }

                .main_divtext {
                    text-align: center;
                    padding-bottom: 12px;
                    padding-top: 10px;

                    .currency_text {
                        font-family: Poppins;
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 15px;
                        letter-spacing: 0em;
                        text-align: center;
                        color: #025041;

                    }

                    .country_text {

                        font-family: Poppins;
                        font-size: 12px;
                        font-weight: 600;
                        line-height: 15px;
                        letter-spacing: 0em;
                        text-align: center;
                        color: #282828;

                    }

                    .Typography_clsamount {
                        font-family: Poppins;
                        font-size: 10px;
                        font-weight: 500;
                        color: #025041;
padding-top: 3px;
                    }
                }
            }
        }
    }
}
.grid_currency_box {
    padding: 16px 32px;

    .grid_button_contean {
        padding: 6px 6px;

        .box_currency {
            background: #F2F8F7;
            border-radius: 8px;
            width: 100%;
            height: 100%;
            border: 0px solid;

            // margin: 10px;
            .currency_img {
                width: 39px;
                height: 28px;

                img {
                    border-radius: 50%;
                    width: 100%;
                    height: 100%;

                }
            }

            .main_divtext {
                text-align: center;
                padding-bottom: 12px;
                padding-top: 10px;

                .currency_text {
                    font-family: Poppins;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 15px;
                    letter-spacing: 0em;
                    text-align: center;
                    color: #025041;

                }

                .country_text {

                    font-family: Poppins;
                    font-size: 12px;
                    font-weight: 600;
                    line-height: 15px;
                    letter-spacing: 0em;
                    text-align: center;
                    color: #282828;

                }

                .Typography_clsamount {
                    font-family: Poppins;
                    font-size: 10px;
                    font-weight: 500;
                    color: #025041;
padding-top: 3px;
                }
            }
        }
    }
}