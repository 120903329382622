.Payment_success_container {
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .box_success {
        background: #FFFFFF;
        box-shadow: 0px 3px 11px 0px #9E9B9B40;
        padding: 20px 32px;

        .typo_heding {
            font-family: Poppins;
            font-size: 24px;
            font-weight: 600;
            line-height: 33px;
            letter-spacing: 0em;
            text-align: left;
            color: #025041;

        }

        .typo_dec {
            font-family: Poppins;
            font-size: 12px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: left;
            color: #1D1D1D;
            padding: 15px;
        }

        .button_Goback {
            background: #025041;
            height: 35px;
            font-family: Poppins;
            font-size: 12px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0em;
            // text-align: left;
            color: #FFFFFF;
            border-radius: 6px;
            border: 0px solid;
            width: 100%;
            margin-top: 12px;
        }

        .button_Goback:hover {
            background: #025041;
            color: #FFFFFF;
            border: 0px solid;
        }
    }
}