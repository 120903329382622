// .payDetails-modal {

//   &__btn-row {
//     display: flex;
//     align-items: center;
//     justify-content: flex-end;
//     margin-top: 30px;
//     column-gap: 10px;

//     .btn {
//         font-family: 'Poppins';
//         font-style: normal;
//         font-weight: 400;
//         font-size: 15px;
//         line-height: 24px;
//         letter-spacing: 0.05em;
//         color: #FFFFFF;
//         border-radius: 6px;
//         &:disabled {
//             background: #CFCFCF !important;
//         }
//     }

//     &__green {
//         background: #025041 !important;
//         color: #FFFFFF !important;
//         border-color: #CFCFCF !important;
//         padding: 11px 40px;
//     }

//     &__white {
//         // color: #707070 !important;
//         color: #000 !important; /* added by  by VL 9 mar NIO-25 */ 
//         background-color: #FFFFFF !important;
//         border-color: #015241 !important;
//         padding: 11px 25px;
//     }
// }
//   .modal-dialog {
//     position: fixed;
//     margin: auto;
//     height: 100%;
//     max-width: 560px;
//     width: 560px !important;
//     -webkit-transform: translate3d(0%, 0, 0);
//     -ms-transform: translate3d(0%, 0, 0);
//     -o-transform: translate3d(0%, 0, 0);
//     transform: translate3d(0%, 0, 0);
//   }
//   &__header{
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     padding: 16px 20px 0px 24px;
//   }
//   &__cereatetext{
//     border-bottom: 4px solid #025141!important;
//     border-radius: 8px 8px 0 0;
//     //color: #111!important;
//     font-family: Poppins;
//     font-size: 14px;
//     font-style: normal;
//     font-weight: 500;
//     line-height: 20px;
//     color: #025041; /* added by  by VL 9 mar NIO-25 */ 
//     // padding: 14px 10px; 
//    }
//   &__whitebtn{
//     font-family: Poppins !important;
//     font-size: 16px !important;
//     font-style: normal !important;
//     font-weight: 500 !important;
//     line-height: 20px;
//     width: 100px;
//     height: 46px;
//     color: rgb(17, 17, 17);
//     background-color: #ffffff;
//     border: none;
//   }
//   &__borderbtn{
//     border: 1px solid;
//   }
//   &__whitebtn:hover{
//     border: 1px solid !important;
//     border-radius: 6px !important;
//     font-family: Poppins !important;
//     font-size: 16px !important;
//     font-style: normal !important;
//     font-weight: 500 !important;
//     line-height: 20px !important;
//     width: 100px;
//     height: 46px;
//     color: rgb(17, 17, 17);
//     background-color: #ffffff;
//   }
//   &__greenbtn{
//     font-family: Poppins !important;
//     font-size: 16px !important;
//     font-style: normal !important;
//     font-weight: 500 !important;
//     color: white;
//     width: 100px;
//     height: 46px;
//     border: none;
//     border: 1px solid #025041 !important;
//     background-color: #025141 !important;
//   }
//   &__greenbtn:hover{
//     border: 1px solid !important;
//     border-radius: 6px !important;
//     font-family: Poppins !important;
//     font-size: 16px !important;
//     font-style: normal !important;
//     font-weight: 500 !important;
//     line-height: 20px !important;
//     width: 100px;
//     height: 46px;
//     color: white;
//     border: 1px solid #025041 !important;
//     background-color: #025141 !important;
//   }
//   &__footer{
//     position: sticky;
//     bottom: 00;
//     display: block;
//     border-top: 1px solid #eee;
//     background: white;
//   }
//   .modal,
//   .right,
//   .fade,
//   .modal-dialog {
//     right: 0px;
//     -webkit-transition: opacity 0.3s linear, right 0.3s ease-out !important;
//     -moz-transition: opacity 0.3s linear, right 0.3s ease-out !important;
//     -o-transition: opacity 0.3s linear, right 0.3s ease-out !important;
//     transition: opacity 0.3s linear, right 0.3s ease-out !important;
//   }

//   .modal-content {
//     height: 100% !important;
//     overflow-y: auto;
//   }

//   .nav-tabs {
//     border-bottom: none !important;
//     padding: 0 8px;
//     display: flex;
//     justify-content: space-between;
//   }

//   .nav-tabs .nav-link.active,
//   .nav-tabs .nav-item.show .nav-link {
//     padding: 14px 10px;
//     font-family: "Poppins";
//     font-style: normal;
//     font-weight: 500;
//     font-size: 14px;
//     line-height: 20px;
//     color: #111111 !important;
//     border-color: #ffffff !important;
//     border-bottom: 4px solid #025141 !important;
//     border-radius: 8px 8px 0px 0px;
//   }

//   .nav-tabs .nav-link {
//     padding: 14px 10px;
//     font-family: "Poppins";
//     font-style: normal;
//     font-weight: 500;
//     font-size: 14px;
//     line-height: 20px;
//     color: #000 !important;
//     border-color: #ffffff !important;
//   }

//   // .btn {
//   //   font-family: "Poppins";
//   //   font-style: normal;
//   //   font-weight: 400;
//   //   font-size: 10px;
//   //   line-height: 20px;
//   //   height: 32px;
//   //   padding: 6px 28px;
//   //   border-radius: 6px;
//   // }

//   .modal-footer {
//     margin-top: auto;
//   }
// }


// // .modal.right.fade .modal-dialog {
// //     right: -320px;
// //     -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
// //        -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
// //          -o-transition: opacity 0.3s linear, right 0.3s ease-out;
// //             transition: opacity 0.3s linear, right 0.3s ease-out;
// // }
// @media only screen and (max-width: 450px) {

// .payDetails-modal .modal-dialog {
//   width: 345px !important;
// max-width: none !important;
// }
// .payDetails-modal__btn-row {
// display: block !important;
// }
// }



.height {
  height: 94vh;
  display: flex;
  padding: 10px 43px;
}

@media only screen and (max-width: 450px) {

.container_div{
padding: 10px 10px !important;
}

}