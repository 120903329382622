.MyAccounts_container {
    padding: 11px 24px;
    border-radius: 5px;
    background: #FFF;
    height: 100%;
    box-shadow: rgba(17, 17, 17, 0.1) 0px 1px 13px 0px;
    position: relative;
}

.boxheding {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 16px;

    a {
        padding-top: 5px;
    }
}

.boxLine {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .boxLine_left {
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        color: #000 !important;
    }

    .boxLine_right {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #000;

        span {
            font-size: 11px;

        }
    }
}

.box_bottam {
    display: flex;
    align-items: center;
    padding-top: 41px;
    justify-content: end;
}

.ReceivepaymentsButton {
    padding: 5px 10px 5px 5px;
    border-radius: 5px;
    background: #025041;
    border: 0px solid;
    display: flex;
    align-items: center;
margin-right: 9px;
    // width: ;
    p {
        font-family: Poppins;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        text-align: center;
        color: #FFF;
        padding-left: 4px;
    }
}

.ReceivepaymentsButton:hover {
    background: #025041;
    border: 0px solid;
}

.ReceivepaymentsButton:active {
    background: #025041;
    border: 0px solid;
    background-color: #025041 !important;
}

.ReceivepaymentsButton:focus-visible {
    background: #025041;
    border: 0px solid;
    box-shadow: none;
}