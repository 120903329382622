.container {
  min-height: 100%;
  display: flex;
  background-size: cover;
  align-items: center;
  justify-content: center;
}

.register-container {
  background: url("/public/assets/3.gif") center;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  /* max-width: 90%; */
  max-height: 85vh;
  margin: auto;
  /* background-color: #fff; */
  /* box-shadow: 0px 2px 30px #ccc6; */
}
.grid_img_container {
  background-image: url('../../../public/LoginSildbar.svg');
  background-repeat: no-repeat;
  background-size:cover;
  background-color: #025041;
  background-position: center;
  height: 100vh;
  border-radius: 10px;
}
.register-container_two {
  display: flex;
  align-items: center;
  justify-content: center;
  /* max-width: 90%;
  max-height: 85vh;
  margin: 47px auto 10px auto; */

  background-color: #fff;
  box-shadow: 0px 2px 30px #ccc6;
  width: 78%;
  margin: 0 auto;
}

.login-container_page {
  background-color: #fff;
  box-shadow: 0px 2px 30px #ccc6;
  height: 80vh;
}

.login-container {
  background: url("/public/assets/3.gif") center;

}

.business-container {
  background: url("/public/assets/3.gif") center;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: 150%;
  background-position: top right;
  background-color: #055141;
}

.contebnar_miani_data {
  background: url("/public/assets/3.gif") center;
  padding: 65px 50px 50px 50px;
  height: 100vh;
  /* height: 100vh; */
  display: flex;
  align-items: center;
}

.contebnar_miani_data_two {
  background: url("/public/assets/3.gif") 100%;
  height: 100%;
  padding: 50px 50px 50px 50px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
}

/* 
.wrapper{
  width: 496px;
  padding: 20px;
  background-color:white;
  padding: 48px 16px;
  border: 1px solid #DDDDDD;
  border-radius: 8px;
  @media only screen and (max-width: 380px){
      {width:"75%"}
  }
}

.login-container > .wrapper{
  width: 25%;
} */

.btn-link {
  padding: 5px;
  margin: 5px 0px;
  text-decoration: underline;
  cursor: pointer;
}

.title {
  font-size: 32px;
  display: flex;
  justify-content: center;
  /* width: 464px; */
  font-weight: 600;
  line-height: 40px;
  text-align: center;
  color: #025041;
}

.form-input {
  display: flex;
  /* width: 464px; */
  flex-direction: column;
  /* align-items: center; */
}

.form-input_line {
  flex-direction: column;
  width: 80%;
  display: grid;
  align-items: center;
  position: relative;
  height: 100vh;
}

.lohin_container_class {
  height: 100vh;

}

/* .grid_img_container {
  background-image: url('../../../public/Group 2499.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #025041;
  background-position: center;
  height: 100vh;
  border-radius: 10px;
} */
.des_data {
  color: #474747;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.helite_text {
  color: #474747;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.div_pading_data {
  text-align: center;
}
.grid_img_container_register {
  background-image: url('../../../public/Group 2499.svg');
  /* background-size: cover; */
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #025041;
  background-position: center;
  border-radius: 10px;

  /* height: 100vh; */
}
#CountryDropdown_class{
  font-size: 12px;
}
.p_Signup_heding {
  font-family: Poppins;
  font-size: 32px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #025041;
padding: 13px 0px;
}

.resend_conatenar {
  display: flex;
  justify-content: center;

}

.resend_button {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #1D1D1D !important;
  background-color: #fff !important;
  border: 0px solid !important;
  margin-top: 16px;
}

.login_email_and_phone {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #025041 !important;
  background-color: #fff !important;
  border: 0px solid !important;
  margin-top: 16px;
}

.grid_verifyemail {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

#req_text_atage {
  display: grid;
  justify-content: end;
}

.classname_login_page {
  gap: 6px;
  display: grid;
}

.footer_text {
  display: flex;
  justify-content: end;
  align-items: center;
  /* padding-top: 100px; */
}

.form-input .first-last-name {
  /* display: grid; */
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  margin-bottom: 10px;
  display: flex;
}

.form-text {
  margin: 0px 0px 0px 0px;
  padding: 10px;
}

.invalid-feedback {
  color: red;
  font-weight: 500;
}

.title-login {
  font-size: 24px;
  font-weight: 300;
  display: flex;
  justify-content: flex-start;
}

.pwd-container {
  position: relative;
}

.pwd-container img {
  cursor: pointer;
  position: absolute;
  width: 20px;
  right: 8px;
  top: 15px;
  background: transparent;
}

.inputBx {
  /* display: flex; */
  /* flex-direction: column; */
  width: 100%;
  padding-bottom: 10px;
}

.formBx {
  display: flex;
  flex-direction: column;
  width: 30%;
  border-radius: 8px;
  padding: 10px 18px;
}

updatepasstext .inputBx span {
  width: 40%px;
  height: 20px;
  font-family: "Faktum";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #025041;
  flex: none;
  order: 0;
  flex-grow: 0;
  padding-top: 12px;
}

.formBx .inputBx input {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 16px;
  gap: 8px;
  width: 100%;
  height: 48px;
  background: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 8px;
  flex: none;
  order: 0;
  flex-grow: 1;
}

.formBx .inputBx input:hover {
  border-color: #023d41;
  box-shadow: rgba(22, 211, 123, 0.2) 0px 0px 0px 4px 2px 2px 2px 2px;
  transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
}

.formBx_tow .inputBx input {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 16px;
  gap: 8px;
  width: 100%;
  height: 48px;
  background: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 8px;
  flex: none;
  order: 0;
  flex-grow: 1;
}

.formBx_tow .inputBx input:hover {
  border-color: #023d41;
  box-shadow: rgba(22, 211, 123, 0.2) 0px 0px 0px 4px 2px 2px 2px 2px;
  transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
}

.form-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 16px;
  gap: 8px;
  width: 368px;
  height: 48px;
  background: #023d31;
  border-radius: 6px;
  flex: none;
  order: 0;
  flex-grow: 1;
}

.formBx .inputBx input[type="submit"]:hover {
  background: #023d31;
}

.formBx .remember {
  margin-bottom: 10px;
  color: #023d41;
  font-weight: 400;
  font-size: 14px;
  width: 80%;
}

.formBx_tow .inputBx input[type="submit"]:hover {
  background: #023d31;
}

.formBx_tow .remember {
  margin-bottom: 10px;
  color: #023d41;
  font-weight: 400;
  font-size: 14px;
  width: 80%;
}

.form-select:focus {
  box-shadow: 0px 0px 0px 4px rgba(1, 82, 65, 0.25) !important;
  border-color: #015241 !important;
}

.form-select:focus-visible {
  box-shadow: 0px 0px 0px 4px rgba(1, 82, 65, 0.25) !important;
  border-color: #015241 !important;
}

.formBx .remember input[type="checkbox"] {
  margin-right: 0.2rem;
}

.formBx .remember .link {
  width: 368px;
  height: 20px;
  font-family: "Faktum";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-decoration-line: underline;
  color: #000;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.formBx .inputBx p {
  width: 278px;
  height: 20px;
  font-family: "Faktum";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #025041;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.formBx .inputBx button {
  width: 80%;
  padding: 10px 20px;
  outline: none;
  font-weight: 400;
  border: 1px solid #c1c9c1;
  font-size: 16px;
  letter-spacing: 1px;
  border-radius: 10px;
  background: transparent;
  color: white;
}

.button_with_SMS {
  border: 1px solid #025041 !important;
  font-family: Poppins !important;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;

  border-radius: 10px;
  background: #fff !important;

  span {
    color: #025041 !important;
  }
}

.formBx .inputBx button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 16px;
  gap: 8px;
  margin-top: 10px;

  width: 100%;
  height: 48px;

  background: #025041;
  border-radius: 6px;
  flex: none;
  order: 0;
  /* flex-grow: 1; */
}

.formBx .inputBx button[type="submit"] span {
  height: 24px;
  font-family: "Faktum";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: #ffffff;
  flex: none;
  order: 1;
  flex-grow: 0;
  min-width: 24px;
}

.formBx .inputBx button[type="submit"]:hover {
  background: #023d31;
}


.formBx_tow .remember input[type="checkbox"] {
  margin-right: 0.2rem;
}

.formBx_tow .remember .link {
  width: 368px;
  height: 20px;
  font-family: "Faktum";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-decoration-line: underline;
  color: #000;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.formBx_tow .inputBx p {
  width: 278px;
  height: 20px;
  font-family: "Faktum";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #025041;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.formBx_tow .inputBx button {
  width: 80%;
  padding: 10px 20px;
  outline: none;
  font-weight: 400;
  border: 1px solid #c1c9c1;
  font-size: 16px;
  letter-spacing: 1px;
  border-radius: 10px;
  background: transparent;
  color: white;
}

.formBx_tow .inputBx button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 16px;
  gap: 8px;
  margin-top: 10px;

  width: 100%;
  height: 48px;

  background: #025041;
  border-radius: 6px;
  flex: none;
  order: 0;
  /* flex-grow: 1; */
}

.formBx_tow .inputBx button[type="submit"] span {
  height: 24px;
  font-family: "Faktum";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: #ffffff;
  flex: none;
  order: 1;
  flex-grow: 0;
  min-width: 24px;
}

.formBx_tow .inputBx button[type="submit"]:hover {
  background: #023d31;
}

.invalid-feedback {
  color: red;
}

.mobile-no-input {
  display: flex;
  flex-direction: row;
}

.inputBx-mobile-prefix {
  width: 30%;
}

.inputBx-mobile {
  width: 70%;
  display: grid;
  padding-left: 0.2rem;
  font-size: 12px !important;
  font-family: "Poppins", sans-serif;

}

.verify-text p {
  color: #000000;
}

.text-left {
  text-align: left !important;
}

.font-14 {
  font-size: 14px;
}

.auto-logout-text {
  color: #FF3922;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  margin: 11px 0px;
}

.secend_grid .rsis-container div {
  background-size: 100% 100% !important;
}


@media only screen and (max-height: 1350px) {
  .contebnar_miani_data_two {
    height: 100vh;

  }

}

@media only screen and (max-height: 850px) {
  .contebnar_miani_data_two {
    height: 100%;
  }
}

@media only screen and (max-width: 900px) {
  .contebnar_miani_data {
    /* padding: 50px 15px; */
    height: 100%;
  }

  .contebnar_miani_data_two {
    padding: 50px 50px 50px 50px;

    height: 100%;
  }

  .register-container_two {
    /* width: 98%; */

  }

  .form-input {
    width: 100%;
  }
}

@media only screen and (max-width: 500px) {
  .form-input_line {
width: 92%;
  }
  .contebnar_miani_data {
    padding: 50px 15px;
    height: 100%;
  }

  .contebnar_miani_data_two {
    padding: 50px 15px;
    height: 100%;
  }

  .register-container_two {
    width: 98%;

  }

  .form-input {
    width: 100%;
  }
}