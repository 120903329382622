.box_wraper {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 20px 0px 20px 0px;
    margin: 10px;
    border-radius: 5px;

    .box_header {
        padding: 5px 0px 15px 20px;
        border-bottom: 1px solid #dddddd;
    }

    .box_heading {
        font-size: 15px;
        font-weight: 600;
    }
}

.box_body {
    padding: 20px;

    .country_name {
        margin-bottom: 15px;
        font-size: 14px;
        font-weight: 500;
    }

    .country_name_img {
        font-size: 14px;
    }

    .currency_name {
        font-size: 14px;
        // font-weight: 500;
    }

    .currency_list {
        // background: rgb(236 235 235 / 25%);
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px;
        border-radius: 5px;
        line-height: 15px;
    }

    .buttonSection {
        display: flex;
        justify-content: center;
    }

    .submitButton {
        border: none;
        border-radius: 5px;
        color: #fff;
        background-color: #055041;
        margin-top: 40px;
        padding: 10px 0px 10px 0px;
        font-family: Poppins;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        width: 20%;
    }

    .submitButton:focus {
        background-color: #055041 !important;
    }

    .submitButton:focus-visible {
        box-shadow: none !important;

    }

    .submitButton:active {
        background-color: #055041 !important;
    }
}

// checkbox-style
.inputCurrency[type="checkbox"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 20px;
    height: 20px;
    border: 2px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    cursor: pointer;
    outline: none;
}

.inputCurrency[type="checkbox"]:checked {
    border-color: #055041;
}

.grid_contenar {
    display: flex;
    justify-content: space-between;
}

.inputCurrency[type="checkbox"]:checked::after {
    content: '✔';
    display: block;
    color: #055041;
    font-size: 16px;
    text-align: center;
    line-height: 20px;
}