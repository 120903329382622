@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;400;500;600;700&display=swap");

.batninnitmenu {
  color: rgb(0, 0, 0) !important;
  text-decoration: none;
  display: flex !important;
  -webkit-box-align: center !important;
  align-items: center !important;
  padding: 8px !important;
  border-radius: 4px !important;
  margin-top: 5px !important;
  width: 100% !important;
  justify-content: left !important;
  text-transform: capitalize !important;

  p {
    color: rgb(40, 40, 40);
    margin-bottom: 0px;
    box-sizing: border-box;
    margin: 0px 0px 0px 8px;
    min-width: 0px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    font-family: "Poppins", sans-serif;

    // flex: 1 1 auto;

  }
}

.batninnitmenu_sub {
  color: rgb(0, 0, 0) !important;
  text-decoration: none;
  display: flex !important;
  -webkit-box-align: center !important;
  align-items: center !important;
  padding: 8px !important;
  border-radius: 4px !important;
  margin-top: 5px !important;
  justify-content: left !important;
  text-transform: capitalize !important;
  margin-left: 16px;
  width: 100%;

  p {
    color: rgb(40, 40, 40);
    margin-bottom: 0px;
    box-sizing: border-box;
    margin: 0px 0px 0px 8px;
    min-width: 0px;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    font-family: "Poppins", sans-serif;

    // flex: 1 1 auto;


  }
}
.active_btn_sub {
  background: #fff !important;

  color: rgb(0, 0, 0) !important;
  text-decoration: none;
  display: flex !important;
  -webkit-box-align: center !important;
  align-items: center !important;
  padding: 8px !important;
  border-radius: 4px !important;
  margin-top: 8px !important;
  width: 100% !important;
  justify-content: left !important;
  text-transform: capitalize !important;
  margin-left: 16px;
  p {
    color: rgb(40, 40, 40);
    margin-bottom: 0px;
    box-sizing: border-box;
    margin: 0px 0px 0px 3px;
    min-width: 0px;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    font-family: "Poppins", sans-serif;

    // flex: 1 1 auto;

  }
}
.batninnitmenu_twoo {
  color: rgb(0, 0, 0) !important;
  text-decoration: none;
  display: flex !important;
  -webkit-box-align: center !important;
  align-items: center !important;
  padding: 6px 8px !important;
  border-radius: 4px !important;
  margin-top: 8px !important;
  width: 100% !important;
  justify-content: left !important;
  text-transform: capitalize !important;
  min-height: 0px !important;
  height: 40px;

  div {
    margin: 0px !important;
  }

  p {
    color: rgb(40, 40, 40);
    margin-bottom: 0px;
    box-sizing: border-box;
    margin: 0px 0px 0px 8px;
    min-width: 0px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    font-family: Poppins;

    // flex: 1 1 auto;

  }
}

#submenu_id {}

.box_dialog_main {
  padding: 14px 32px;
}

.typo_text {
  font-family: 'Poppins' !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 16px !important;
  text-align: center;
  color: #282828 !important;
}

.grid_senter {
  background-color: #FFFFFF;
  height: 170px;
}

.box_btn_cencal {
  display: flex;
  justify-content: space-between;
  padding-top: 30px;
}

.delete_btn {
  font-family: 'Poppins' !important;
  font-size: 14px !important;
  color: #FF3A23 !important;
  font-weight: 400 !important;
  text-transform: capitalize !important;
}

.cancel_btn {
  font-family: 'Poppins' !important;
  font-size: 14px !important;
  color: #025141 !important;
  font-weight: 400 !important;
  text-transform: capitalize !important;
}

.jhduhaudhdahddh {
  padding: 0px !important;
}

.Accordion_daatatatta {
  background-color: transparent !important;
  box-shadow: none !important;
}

.Accordion_daatatatta::before {
  background-color: transparent !important;
  box-shadow: none !important;
}

.active_btn {
  background: #fff !important;

  color: rgb(0, 0, 0) !important;
  text-decoration: none;
  display: flex !important;
  -webkit-box-align: center !important;
  align-items: center !important;
  padding: 8px !important;
  border-radius: 4px !important;
  margin-top: 8px !important;
  width: 100% !important;
  justify-content: left !important;
  text-transform: capitalize !important;

  p {
    color: rgb(40, 40, 40);
    margin-bottom: 0px;
    box-sizing: border-box;
    margin: 0px 0px 0px 8px;
    min-width: 0px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    font-family: "Poppins", sans-serif;

    // flex: 1 1 auto;

  }
}