.recipient-bank-container {
    background: #FFFFFF;
    border-radius: 8px;
    padding: 32px;
    width: 635px;
    margin: 40px auto;
    box-shadow: 0 3px 11px hsla(0, 2%, 61%, .25);

    .text-field {
        background: white;
    }

    &__heading {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        text-align: center;
        color: #292929;
        margin-bottom: 16px;
    }

    label {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        color: #202D4C;
        margin-bottom: 4px;
    }

    &__option-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #FFFFFF;
        border: 1px solid #E9EAED;
        border-radius: 5px;
        padding: 7px 12px;
        margin-bottom: 8px;
        cursor: pointer;


        &__txt {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            color: #000;
        }
    }

    &__select-options {
        position: absolute;
        z-index: 1;
        background: #FFFFFF;
        box-shadow: 0px 6px 17px -1px rgba(92, 92, 92, 0.25);
        border-radius: 5px;
        padding: 14px 36px;
        width: 587px;

        &__item {
            padding: 10px 12px;
            border: 1px solid #DDDDDD;
            border-radius: 4px;
            margin-bottom: 7px;
            cursor: pointer;

            img,
            svg {
                margin-right: 10px;
            }
        }

        &__txt {
            // font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #111111;
        }
    }

    .doc-form-input {
        outline: none;
        padding: 0px 12px;
        height: 40px;
        background: #FFFFFF;
        outline: none;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #111111;
        border-radius: 5px;
        border: 1px solid #E9EAED;

        &::placeholder {
            color: #6D6B6B;
            font-weight: 400;
        }
    }

    &__btn-row {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 19px;

        .btn {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.05em;
            color: #FFFFFF;
            border-radius: 6px;
        }

        &__grey {
            background: #F3F3F3 !important;
            color: #111111 !important;
            border-color: #F3F3F3 !important;
            padding: 11px 40px;
            width: 100%;
        }
    }
}
.heding_typename{
    font-family: Poppins;
font-size: 12px;
font-weight: 500;
line-height: 20px;
letter-spacing: 0em;
text-align: left;
color: #025041;

}
@media only screen and (max-width: 450px) {
    .recipient-bank-container {
        padding: 20px 15px;
        width: 100%;
    }

    .user-detail-container {

        &__wrapper {
            padding: 27px 15px;
        }
    }

    .complete-profile__saventmm {
        width: 137px;
        text-align: center;
        padding: 11px 30px !important;
    }

    .complete-profile__submitbtnlist {
        // width: 137px;
        text-align: center;
        padding: 11px 30px !important;
    }

}

@media only screen and (max-width: 390px) {
    .complete-profile__saventmm {
        // width: 117px;
        text-align: center;
        padding: 11px 20px !important;
    }

    .complete-profile__submitbtnlist {
        width: 117px;
        text-align: center;
        padding: 11px 20px !important;
    }
}

@media only screen and (max-width: 375px) {
    .complete-profile__saventmm {
        width: 87px;
        // text-align: center;
        padding: 11px 10px !important;
    }

    .complete-profile__submitbtnlist {
        width: 87px;
        // text-align: center;
        padding: 11px 10px !important;
    }
}