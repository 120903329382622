.box_wraper {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin: 10px;
    border-radius: 5px;

    .box_header {
        // padding: 5px 0px 15px 20px;
        border-bottom: 1px solid #dddddd;
        padding-bottom: 20px;
    }

    .box_heading {
        font-size: 15px;
        font-weight: 600;
    }

    .businessType {
        display: flex;
        justify-content: space-between;
        padding-top: 20px;
    }

    .sectionHeading {
        margin-top: 5px;
        padding: 15px;
        background-color: #FBFBFB;
        border-radius: 5px;
        font-family: Poppins;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;

    }

    .businessDetails_section {
        margin-top: 10px;
    }

    .itemsInput {
        background-color: #F5F5F5;
        border: 1px solid #dad8d8;
        border-bottom: none;
        margin-top: 5px;
        padding: 10px 10px;
    }

    .sectionWrappers {
        background-color: #FBFBFB;
        border-radius: 10px;
        padding: 10px 20px 20px 20px;
        margin-top: 10px;
    }

    .currnxychecks {
        display: flex;
        justify-content: start;
        padding: 20px;
        border-radius: 6px;
        background-color: #FBFBFB;
    }

    .buttonSection {
        display: flex;
        justify-content: center;
    }

    .submitButton {
        border: none;
        border-radius: 5px;
        color: #fff;
        background-color: #055041;
        margin-top: 40px;
        padding: 10px 0px 10px 0px;
        width: 20%;
        font-family: Poppins;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
    }
}

.boxHeadings {
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    color: #1D1D1D;
    font-family: Poppins;
}

.lastRow {
    display: flex;
    justify-content: space-between;
}

.currencyName {
    display: flex;
    align-items: center;
}

.lebalClass {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    // text-align: center;
    color: #1D1D1D;
}

.IconDowntype {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 20%;

    h6 {
        margin: 0px;
        padding-right: 20px;
    }
}

@media (max-width: 450px) {
    .IconDowntype {
        width: 100%;
        margin-bottom: 10px;

    }
}