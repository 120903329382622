.payment-journey-container {
  padding: 45px 105px;
  background: #fff;
  height: 100vh;

  &__dflex {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 65px;
  }

  &__detailbox {
    background: #ffffff;
    border-radius: 8px;
    padding: 32px 0;
    box-shadow: 0 3px 11px hsla(0, 2%, 61%, 0.25);


    &__name {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      font-weight: bold;
      display: flex;
      align-items: center;
      color: #000;
      margin-bottom: 16px;

      svg {
        margin-right: 8px;
      }
    }

    .plr-32 {
      padding-left: 32px;
      padding-right: 32px;
    }

    &__currency {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 40px;
      color: #282828;
    }

    &__amount {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 30px;
      line-height: 40px;
      color: #282828;
      padding-left: 3px;
    }

    &__btn-row {
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 16px;
      margin: 16px 0;
    }

    .btn {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      height: auto;
      padding: 12px 16px;
      border-radius: 6px;
      width: 100%;
    }


    &__white-btn {
      border: 1px solid #025041 !important;
      background-color: white !important;
      color: #025141 !important;
      color: #000 !important;
      /* added by  by VL 9 mar NIO-25 */
      border: 1px solid #025041 !important;
      height: auto;
    }



    &__green {
      background: #025041 !important;
      color: #ffffff !important;
      border-color: #cfcfcf !important;
      padding: 11px 40px;
    }

    &__table {
      border: 1px solid #dddddd;
      border-radius: 8px;
      padding: 0 10px;
      margin-bottom: 18px;

      .bottomNone {
        border-bottom: none !important;
      }

      .b-bot {
        border-bottom: 1px solid #dddddd;
      }

      .d-flex {
        display: flex;
        justify-content: space-between;
        // margin-bottom: 10px;
        align-items: center;
        height: 45px;

        .left {
          font-weight: 400;
          font-size: 13px;
          line-height: 30px;
          color: #000;
        }

        .right {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #111111;
        }
      }
    }

    &__border {
      border-bottom: 1px solid #dddddd;
      margin-top: 40px;
    }

    &__heading {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      margin: 5px 0px;
      // color: #515151;
      // margin: 25px 0;
      // margin-top: 29px;
      color: #000 !important;
      /* added by  by VL 9 mar NIO-25 */
    }
  }
}
.noDataClass{
  display: grid;
  gap: 12px;
  justify-content: center;
  align-items: center;
  padding: 30px 0px;
}
.noDataimg{
  display: flex;
  justify-content: center;
  width: 100%;
}
#bold_data_heding {
  font-weight: 700;
  color: #000;
}

.topup_modal .modal-content {
  width: 600px;
  background: #ffffff;
  height: auto;
  margin: 0 auto;
}

.heding_tesyssjsj {
  color: #025041;
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
}

.conten_data_contenar_data {
  background: #ffffff;
  box-shadow: 0px 0px 14px rgba(174, 174, 174, 0.34);
  border-radius: 0px 0px 12px 12px;
  padding: 33px 20px 40px 20px;
  border-radius: 8px;
}

.div_heding_datata {
  text-align: center;

  p {
    color: #292929;
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 24px;
  }
}

.conten_data_contenar_data h1 {
  margin: 30px 0px;
  margin-bottom: 25px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #111111;
  display: flex;
  gap: 5px;
}

.add-btn {
  background: transparent !important;
  color: #282828 !important;
  border: none !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  padding: 12px 16px;
  margin-bottom: 10px;

  svg {
    margin-left: 10px;
  }
}

.mb-20 {
  margin-bottom: 20px;
}

.error {
  border-color: red;
}

.error-message {
  color: #ff6565;
  padding: 0.5em 0.2em;
  height: 1em;
  font-size: 0.8em;
}

.dot {
  label:after {
    content: '';
    display: inline-block;
    margin-left: 7px;
    vertical-align: middle;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: rgb(255, 71, 93);
  }
}

.row-date {
  display: flex;
  width: 510px;
  gap: 15px;
}

.date-width {
  width: 50%;
  margin: 0 !important;
}

.input-form-wrapper {
  margin: 20px 0px;

  li {
    background-color: #fff;
    color: #000;
  }

  label {
    display: block;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #000;
    margin-bottom: 5px;
  }

  .text-field {
    border: 1px solid #025141;
    border-radius: 5px;
    // width: 368px;
    height: 48px;
    padding: 0px 8px;
    outline: none;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    color: #3a3a3a;
  }

  select {
    border-radius: 5px;
    //width: 368px;
    height: 48px;
    outline: none;
    padding: 0px 8px;
    color: #3a3a3a;
    font-size: 12px;
  }

  select:focus {
    box-shadow: 0px 0px 0px 4px rgba(1, 82, 65, 0.25);
    border-color: #015241;
  }

  .doc-input-wrapper {
    display: flex;
    align-items: center;
    height: 48px;
    background: #ffffff;
    border: 1px solid #dddddd;
    outline: none;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #111111;
    border-radius: 8px;

    &.input {
      // padding: 0px 12px;
    }

    input {
      border: none;
      height: 100%;
      font-size: 12px;
    }

    .doc-form-input {
      outline: none;
      font-size: 12px;
    }

    .doc-form-input:focus {
      box-shadow: 0px 0px 0px 4px rgba(1, 82, 65, 0.25);
      border-color: #015241;
    }

    .doc-form-input :focus {
      color: #212529;
      background-color: #fff;
      border-color: #015241;
      outline: 0;
      box-shadow: 0px 0px 0px 4px rgba(1, 82, 65, 0.25);
    }

    .input-mask {
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 16px;
      color: #b8b8b8;
      background-color: #ffffff;
      border: none;
    }
  }

  .doc-date {
    height: 48px;
    font-size: 12px;

    .react-date-picker__wrapper {
      border-color: #dddddd;
      padding: 0px 8px;
      border-radius: 8px;
    }
  }

  .multiselect-container {
    .searchWrapper {
      border-radius: 8px !important;
      display: flex;
      align-items: center;
      padding: 8px !important;
      border-color: #dddddd !important;
      min-height: 48px;
      flex-wrap: wrap;

      .searchBox {
        margin-top: 0px !important;
        font-size: 12px;
      }

      .chip {
        padding: 0px 8px;
        gap: 4px;
        //width: 101px;
        height: 24px;
        background: #ffffff;
        border: 1px solid #dddddd;
        border-radius: 4px;

        font-style: normal;
        font-weight: 500;
        font-size: 11px;
        color: #474747;

        .closeIcon {
          display: none !important;
        }
      }
    }

    input {
      border: none !important;
      height: auto !important;
    }
  }
}

.footer {
  position: sticky;
  bottom: 00;
  display: block;
  border-top: 1px solid #eee;
  background: white;

  .whitebtn {
    font-family: Poppins !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 20px;
    width: 100px;
    height: 46px;
    color: rgb(17, 17, 17);
    background-color: #ffffff;
    border: none;
    border: 1px solid;
    margin-right: 10px;
  }

  .whitebtn:hover {
    border: 1px solid !important;
    border-radius: 6px !important;
    font-family: Poppins !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 20px !important;
    width: 100px;
    height: 46px;
    color: rgb(17, 17, 17);
    background-color: #ffffff;
    margin-right: 10px;
  }

  .greenbtn {
    font-family: Poppins !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    color: white;
    width: 100px;
    height: 46px;
    border: none;
    border: 1px solid #025041 !important;
    background-color: #025141 !important;
  }

  .greenbtn:hover {
    border: 1px solid !important;
    border-radius: 6px !important;
    font-family: Poppins !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 20px !important;
    width: 100px;
    height: 46px;
    color: white;
    border: 1px solid #025041 !important;
    background-color: #025141 !important;
  }
}

.err_text_ajjsjshhs {
  color: #ff6565;
  padding: 0.5em 0.2em;
  height: 1em;
  font-size: 0.8em;
}

.counterparty {
  &__header {
    background: #ffffff;
    box-shadow: 0px 4px 21px rgba(204, 204, 204, 0.25);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 18px 43px;

    &__name {
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 20px;
      font-family: 'Poppins';
      color: #025041;
    }
  }

  .btn {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    padding: 12px 16px;
    border-radius: 6px;
  }

  &__white-btn {
    border: 1px solid #025041 !important;
    background-color: white !important;
    color: #025141 !important;
    margin-right: 12px;
  }

  &__save-btn {
    border: 1px solid #025041 !important;
    background-color: #025141 !important;
    color: #ffffff !important;
    margin-right: 43px;
  }

  h1 {
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    color: #025041;
    font-family: 'Poppins';
    margin-left: 15px;
    padding-left: 43px;
  }
}

.main_fast_and_last {
  display: flex;
  justify-content: space-between;
  align-items: center;

  #fast_name_inpurt_div {
    width: 48%;
  }
}

.main_contenar_listts {
  .box_contean_data_contenar {
    width: 500px;
    margin: auto;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 3px 11px 0px rgba(158, 155, 155, 0.25);
    padding: 20px 35px 20px 35px;

    .p_tage_cerete_data {
      color: #1d1d1d;
      font-family: Poppins;
      font-size: 22px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      text-align: center;
    }

    .maib_redio_button_contenar {
      flex-direction: initial;

      .main_redio_buttton {
        span {
          color: #000;
          font-family: Poppins;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
        }
      }
    }

    .calss_accordion_contenar {
      padding-top: 12px;

      .maian_adaat_listtts {
        border-radius: 5px;
        border: 1px solid #bbb;
        background: #f1f1f1;
        display: flex;
        justify-content: space-between;
        padding: 8px 20px;
        cursor: pointer;

        .accordion_details_contean {
          background-color: #fff;

          .accordion_p_data_contean {
            color: #1d1d1d;
            font-family: Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
          }
        }
      }

      .mainana_cantena_daat_potrrr {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px 0px 0px 0px;

        .Minn_button_mpesa_datata {
          border-radius: 5px;
          border: 1.5px solid #025041;
          width: 48%;
          padding: 16px 10px;
          background-color: #fff;
          color: #000;
          font-family: Poppins;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          text-align: start;
        }

        .Minn_button_mpesa_datata:focus-visible {
          box-shadow: none !important;
        }

        .calss_not_active_data {
          border-radius: 5px;
          border: 1px solid #ddd;
          width: 48%;
          padding: 16px 10px;
          background-color: #fff;
          color: #000;
          font-family: Poppins;
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          text-align: start;
        }

        .calss_not_active_data:focus-visible {
          box-shadow: none !important;
        }
      }
    }

    .main_buuton_line_logg {
      padding: 20px 0px 10px 0px;
    }
  }
}

.Category_label {
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #000;
  margin-bottom: 5px;
}

.calss_accordion_contenar {
  padding-top: 12px;

  .maian_adaat_listtts {
    border-radius: 5px;
    border: 1px solid #bbb;
    background: #f1f1f1;
    display: flex;
    justify-content: space-between;
    padding: 12px 20px;
    cursor: pointer;

    .accordion_details_contean {
      background-color: #fff;

      .accordion_p_data_contean {
        color: #1d1d1d;
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
    }
  }

  .mainana_cantena_daat_potrrr {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px 0px 0px;

    .Minn_button_mpesa_datata {
      border-radius: 5px;
      border: 1.5px solid #025041;
      width: 48%;
      padding: 16px 10px;
      background-color: #fff;
      color: #000;
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      text-align: start;
    }

    .Minn_button_mpesa_datata:focus-visible {
      box-shadow: none !important;
    }

    .calss_not_active_data {
      border-radius: 5px;
      border: 1px solid #ddd;
      width: 48%;
      padding: 16px 10px;
      background-color: #fff;
      color: #000;
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      text-align: start;
    }

    .calss_not_active_data:focus-visible {
      box-shadow: none !important;

    }
  }
}

.des_clos_popup {
  color: #1d1d1d;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}

.main_buuton_line_logg {
  padding: 20px 0px 10px 0px;
}

.text_heding_express {
  color: #1d1d1d;
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  // padding-bottom: 30px;
}

.amont_text_p_chackbox {
  display: flex;
  align-items: center;
}

#button_back {
  border: 0px solid !important;
}

.avayar_text {
  display: flex;
  align-items: center;

  p {
    padding-left: 10px;
  }

  .Avatar_fast {
    width: 32px;
    height: 32px;
    font-family: Poppins;
    color: #055041;
    font-size: 14px;
    background-color: #fff;
  }
}

.avayar_text_error {
  display: flex;
  align-items: center;

  p {
    padding-left: 10px;
    color: rgb(255, 101, 101);
  }

  .Avatar_fast {
    width: 32px;
    height: 32px;
    font-family: Poppins;
    color: red;
    font-size: 14px;
    background-color: #fff;
  }
}

.Autocomplete_class_name {
  height: 45px;
}

.select_payee {
  font-size: 12px;
  // border: 1px solid #dddddd;

}

.css-13cymwt-control {
  height: 46px;
  border: 1px solid #dddddd;
  border-radius: 8px;
}

.css-t3ipsp-control {
  height: 46px;
  border: 1px solid #015241;
  border-radius: 8px;

}

.css-t3ipsp-control:hover {
  border-color: #015241 !important;
  box-shadow: 0px 0px 0px 4px rgba(1, 82, 65, 0.25) !important;
}

.css-t3ipsp-control:focus {
  border-color: #015241 !important;
  box-shadow: 0px 0px 0px 4px rgba(1, 82, 65, 0.25) !important;
}

@media only screen and (max-width: 820px) {
  .height {
    padding: 10px 17px !important;
  }
}

@media only screen and (max-width: 450px) {
  .counterparty {
    h1 {
      padding-left: 0px;
      margin-left: 0px;
    }

    &__save-btn {
      margin-right: 0px;
      padding: 9px 4px !important;
    }

    &__white-btn {
      padding: 9px 4px !important;
      margin-right: 10px;
    }
  }

  .styles_main_tesffsffs__VVD-X {
    padding: 16px 10px;
  }
}

.maib_redio_button_contenar {
  flex-direction: initial;

  .main_redio_buttton {
    span {
      color: #000;
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }
}

@media only screen and (max-width: 820px) {
  .payment-journey-container {
    padding: 10px 32px;
    background: #f9f9f9;
    height: 100vh;

    &__dflex {
      display: block !important;
    }
  }
}

@media only screen and (max-width: 450px) {
  .payment-journey-container {
    padding: 10px 14px;
    background: #f9f9f9;
    height: 100vh;

    &__dflex {
      display: block !important;
    }
  }

  .div_heding_stylesss {
    display: block !important;
  }

  #button_back {
    margin-bottom: 10px;
    background-color: #f9f9f9 !important;
  }
}