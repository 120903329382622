.tags {
  &__header {
    background: #ffffff;
    box-shadow: 0px 4px 21px rgba(204, 204, 204, 0.25);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 18px 43px;

    &__name {
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 20px;
      font-family: "Poppins";
      color: #025041;
    }
  }

  .btn {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    padding: 12px 16px;
    border-radius: 6px;
  }

  &__white-btn {
    border: 1px solid #025041 !important;
    background-color: white !important;
    color: #025141 !important;
  }

  &__save-btn {
    border: 1px solid #025041 !important;
    background-color: #025141 !important;
    color: #ffffff !important;
    margin-right: 43px;
  }

  h1 {
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    color: #025041;
    font-family: "Poppins";
    margin-left: 15px;
    padding-left: 43px;
  }
}

.create_intigretion {
  width: 100%;
  max-height: 250px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 3px 11px 0px rgba(158, 155, 155, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 17px;

  .create_intigretion_btn {
    color: #fff !important;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    display: flex;
    padding: 6px 25px;
    border-radius: 6px;
    background: #025141 !important;
    border: 0px;
  }

  .heading_create {
    color: #2d2c2c;
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.44px;
    margin: 0px;
  }

  .description_create {
    font-size: 13px;
    font-family: Poppins;
    font-weight: 400;
    margin: 0px;
  }

  .list_heading_create {
    color: #2d2c2c;
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.44px;
    margin-bottom: 40px;
  }
}

.list_div_intigretion {
  justify-content: center;
  align-items: center;
  gap: 40px;
  flex-wrap: wrap;
  display: flex;
}

.box_intigretion {
  display: flex;
  width: 280px;
  padding: 30px 14px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 9px;
  background: rgb(255, 255, 255);
  box-shadow: rgba(17, 17, 17, 0.17) 0px 1px 7px 0px;
}

.create_rows {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 35px;

  .row_text {
    color: #000;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    margin: 0px;
  }

  .button_api_credential {
    color: #025041;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    padding: 10px 80px;
    border-radius: 9px;
    border: 1px solid #025041;
    background-color: #ffffff;
  }

  .icon_button_api_credential {
    border-radius: 9px;
    border: 1px solid #6d6b6b;
    background-color: #ffffff;
    padding: 9px;
  }
}

.create_api_box {
  width: 100%;
  justify-content: center;
  display: flex;

  .inside_sub_box {
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 3px 11px 0px rgba(158, 155, 155, 0.25);
    padding: 60px 100px;
    width: 100%;
    display: flex;
    max-width: 580px;
    flex-direction: column;
    justify-content: center;

    .heading_create_api {
      color: #2d2c2c;
      font-family: Poppins;
      font-size: 17px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      text-align: center;
      letter-spacing: 0.34px;
      margin: 0px;
      margin-bottom: 30px;
    }

    .input_box {
      width: 100%;
      display: flex;
      flex-direction: column;
      row-gap: 8px;

      .input_lable {
        color: #515151;
        font-family: Poppins;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        margin: 0px;
        letter-spacing: 0.12px;
      }

      .input_box {
        display: flex;
        width: 100%;
        max-width: 400px;
        height: 48px;
        padding: 14px 23px;
        border-radius: 5px;
        border: 1px solid #b8b8b8;
        font-size: 11px;
      }
    }
  }
}

.DGDGGDGGDBJXJXBX {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.sasjjshshhshhs{
  border: 1px solid #025041 !important;
  background-color: #025141 !important;
  color: #ffffff !important;
  text-transform: capitalize;
  // margin-right: 43px;
  font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    padding: 12px 16px;
    border-radius: 6px;
}

@media only screen and (max-width: 450px) {
  .calss_tage_ajajahhahha {
    padding: 10px 13px !important;
    width: 100%;
    overflow-x: auto;
  }
  .description_create{
    padding: 0px 15px;
    text-align: center;
  }

  .tags {
    h1 {
      padding-left: 0px;
    }

    &__save-btn {
      margin-right: 0px;
    }
  }
}