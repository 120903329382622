.balance {
    &__header {
      background: #ffffff;
      box-shadow: 0px 4px 21px rgba(204, 204, 204, 0.25);
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 18px 32px 18px 32px;
      text-align: center;
  
      &__name {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 20px;
        color: #025041;
      }
    }
  
    .btn {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      padding: 12px 16px;
      border-radius: 6px;
    }
  
    &__white-btn {
      border: 1px solid #025041 !important;
      background-color: white !important;
      color: #000 !important;
    }
  
    &__save-btn {
      border: 1px solid #025041 !important;
      background-color: #025141 !important;
      color: #ffffff !important;
      margin-left: 10px;
    }
  
    &__card {
      padding: 14px;
      grid-template-columns: 1fr;
      row-gap: 6px;
      width: 320px;
      background: #f2f8f7;
      border-radius: 8px;
      height: 135px;
  
      &__total {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        display: flex;
        align-items: center;
        letter-spacing: 0.04em;
        color: #000 !important;
      }
  
      &__unit {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        align-items: center;
        letter-spacing: 0.04em;
        color: #000;
        // padding: 10px 0px 0px 0px;
      }
  
      &__amount {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 28px;
        line-height: 42px;
        display: flex;
        align-items: center;
        letter-spacing: 0.06em;
        color: #257d6b;
        margin-left: 5px;
        // padding: 10px 0px 0px 0px;
      }
  
      &__row {
        display: flex;
      }
    }
  
    &__card_TWO {
      padding: 14px;
      grid-template-columns: 1fr;
      row-gap: 6px;
      width: 320px;
      background: #ffffff;
      box-shadow: 0px 3px 8px rgba(173, 170, 170, 0.25);
      border-radius: 4px;
      border: 2px solid #025041;
      height: 135px;
  
      &__total {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        display: flex;
        align-items: center;
        letter-spacing: 0.04em;
        color: #000 !important;
      }
  
      &__unit {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        align-items: center;
        letter-spacing: 0.04em;
        color: #000;
      }
  
      &__amount {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 28px;
        line-height: 42px;
        display: flex;
        align-items: center;
        letter-spacing: 0.06em;
        color: #257d6b;
        margin-left: 5px;
      }
  
      &__row {
        display: flex;
      }
    }
  
    &__search-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 32px;
  
      a {
        text-decoration: none;
      }
  
      .dflex {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
      }
  
      &__export {
        display: flex;
        align-items: center;
        column-gap: 11px;
        padding: 5px 12px 5px 16px;
        height: 30px;
        background: #ffffff;
        border: 1px solid #dddddd;
        border-radius: 13px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        line-height: 20px;
        letter-spacing: 0.03em;
        color: #282828;
        cursor: pointer;
      }
    }
  
    &__export {
      color: #383838;
      font-size: 14px;
      display: flex;
      height: 48px;
      align-items: center;
      column-gap: 11px;
      padding: 5px 15px;
      background: #ffffff;
      border: 1px solid #dddddd;
      border-radius: 50px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.03em;
      cursor: pointer;
    }
  
    &__filter-wrapper {
      display: flex;
      background: #ffffff;
      outline: none;
      font-style: normal;
      font-weight: 500 !important;
      font-size: 11px !important;
      color: #282828;
  
      .input-group-text {
        border: none;
        height: 48px;
        background-color: #ffffff;
        border-bottom-left-radius: 15px;
        border-top-left-radius: 15px;
      }
  
      &.input {
        height: 48px;
      }
  
      &.dropdown {
        max-width: 114px !important;
        height: 32px;
      }
  
      .form-select {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        line-height: 20px;
        letter-spacing: 0.03em;
        color: #383838 !important;
      }
  
      &.custom {
        width: 94px !important;
        display: flex;
        align-items: center;
        gap: 13px;
        justify-content: center;
        cursor: pointer;
      }
  
      .icon {
        display: flex;
        align-items: center;
        padding: 0px 12px 0px 14px;
        position: absolute;
        z-index: 6;
        background: transparent;
      }
      &__datepicker2 {
        display: flex;
        align-items: center;
        height: 45px;
        background: #f2f8f7;
        border: 1px solid #dddddd;
        outline: none;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #111111;
        border-radius: 8px;
        padding: 10px;
        margin: 0 10px 15px;
        width: 150px;
      }
      .balance-search-input {
        width: 100%;
        border: none;
        border-radius: 100px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 12 px;
        line-height: 20px;
        letter-spacing: 0.03em;
        color: #383838;
        outline: none;
        height: 48px;
        position: relative;
        border: 1px solid #dddddd;
        border-radius: 100px !important;
        padding-left: 40px;
      }
  
      .balance-search-input::placeholder {
        color: #383838;
        font-family: "Poppins";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        /* 181.818% */
        letter-spacing: 0.33px;
      }
  
      .balance-search-input:focus {
        box-shadow: 0px 0px 0px 4px rgba(1, 82, 65, 0.25) !important;
        border-color: #015241 !important;
      }
    }
  
    &__table {
      margin: 18px 32px;
    }
  
    .table> :not(caption)>*>* {
      border-bottom: none;
    }
  
    table {
      width: 100%;
      background: #ffffff;
      box-shadow: 0px 2px 14px 2px rgba(174, 174, 174, 0.25);
      border-radius: 8px;
      padding: 6px;
      margin: 0px !important;
  
      td,
      th {
        text-align: start;
        padding: 15px 10px;
      }
  
      th,
      .th {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 20px;
        letter-spacing: 0.01em;
        color: #000000;
      }
  
      td,
      .td {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        letter-spacing: 0.03em;
        color: #000;
      }
  
      td.type {
        text-transform: capitalize;
      }
    }
  
    .react--wrapper {
      width: 100%;
    }
  
    .react-datepicker__input-container {
      height: 48px;
      border-radius: 50px;
      background: #ffffff !important;
  
      input {
        height: 48px;
        border-radius: 50px;
        background: #ffffff !important;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.03em;
        color: #383838 !important;
        margin: 0 0px 15px;
        width: 100%;
  
        &::placeholder {
          color: #383838 !important;
          font-family: "Poppins";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0.33px;
        }
  
        &::placeholder:hover {
          color: #515151;
          color: white;
        }
  
        &::placeholder:focus:hover {
          color: white;
        }
  
        &::placeholder:hover {
          color: #ffffff !important;
        }
  
        &:focus::-webkit-input-placeholder,
        &:hover::-webkit-input-placeholder {
          color: #ffffff !important;
        }
  
        &:focus {
          box-shadow: 0px 0px 0px 4px rgba(1, 82, 65, 0.25);
          border-color: #015241;
        }
  
        &:hover {
          color: #515151;
          background-color: #3f7268;
          box-shadow: 0px 0px 0px 4px rgba(1, 82, 65, 0.25);
        }
      }
    }
  }
  
  .Congratulations_CLASS {
    color: #025041 !important;
    text-align: center;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600 !important;
    line-height: normal;
  }
  
  .classaButtondata {
    display: flex;
    align-items: center;
  }
  
  #Go_back {
    margin: 0 !important;
    background: #025041 !important;
    color: #ffffff !important;
    border: 0px solid !important;
    width: 105px !important;
    padding: 6px 12px !important;
    height: 37px !important;
    font-size: 14px !important;
  }
  
  .d-flex {
    display: flex;
  }
  
  .card_select_data {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 32px 32px;
  }
  
  .select_main_contean {
    width: 45%;
    display: flex;
    align-items: center;
    justify-content: end;
  }
  
  .alignitem-center {
    align-items: center;
  }
  
  .justify-center {
    justify-content: center;
  }
  
  .table-paginnations p {
    margin: 0;
  }
  
  .tag {
    &_color {
      color: white;
      border-radius: 4px;
      padding: 1px 8px;
      white-space: nowrap;
      cursor: default;
    }
  
    &_payin-tag {
      background-color: #009e7e;
    }
  
    &_payout-tag {
      background-color: #21144c;
    }
  
    &_fees-tag {
      background-color: #f87161;
    }
  }
  
  .text {
    &_payin-tag {
      color: #009e7e;
    }
  
    &_payout-tag {
      color: #21144c;
    }
  
    &_fees-tag {
      color: #f87161;
    }
  }
  
  .tebaldateformette {
    min-width: max-content;
    margin-bottom: 0px;
  }
  
  .view_detail {
    font-weight: 500;
    cursor: pointer;
  }
  
  .addnewbtnclass {
    display: flex;
    justify-content: end;
    align-items: center;
    padding-right: 10px;
  }
  
  .inpagenum {
    padding: 0px 20px 0px 0px;
  
    p {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      margin-bottom: 0px;
    }
  }
  
  .Download_Button {
    border-radius: 50px !important;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px !important;
    line-height: 20px;
    letter-spacing: 0.03em;
    color: #383838 !important;
    width: 100%;
    background-color: #fff !important;
    border: 1px solid #ced4da !important;
    display: flex !important;
  
    p {
      padding-left: 6px;
    }
  }
  
  .nextntmistish {
    background-color: transparent;
    border: none;
    margin-left: 20px;
  }
  
  .add_new_text_p {
    // padding: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
  }
  
  .balance_cat {
    // margin: 0 9px 0px 14px;
    margin: 0px;
  
    select.form-select {
      // width: 185px;
      border-radius: 50px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.03em;
      color: #383838 !important;
    }
  }
  
  .balance-category {
    max-width: 100px;
  }
  
  .balance-category p {
    min-width: auto;
    word-wrap: break-word;
  }
  
  .twosjshhshhshhs {
    display: block;
  }
  
  .ajaajhhahahha {
    display: none;
  }
  
  .export_a {
    border: 1px solid #025041;
    padding: 12px 9px 12px 17px;
    color: #025041;
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    border-radius: 50px;
  
    svg {
      margin-right: 10px;
    }
  }
  
  .selectBoxContean {
    display: flex;
    align-items: center;
  }
  
  .headerLayout {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 75%;
  }
  
  .Buttonontenar {
    display: flex;
    align-items: center;
  }
  
  @media only screen and (max-width: 890px) {
  
    .balance__white-btn,
    .balance__save-btn {
      display: block !important;
    }
  }
  
  @media only screen and (max-width: 820px) {
    .balance {
      &__header {
        // display: block;
        padding: 18px 20px;
      }
  
      &__card {
        margin: 20px 20px;
      }
  
      &__search-row {
        display: block;
        margin: 0 22px;
  
        .dflex {
          // display: block;
        }
      }
  
      &__filter-wrapper {
        &.input {
          width: 100%;
          margin-bottom: 10px;
        }
      }
  
      .react-datepicker-wrapper {
        width: 100%;
        margin-bottom: 10px;
      }
  
      .react-datepicker__input-container {
        width: 100%;
  
        input {
          width: 100%;
        }
      }
    }
  
    .balance_cat {
      margin: 0 0px;
      margin-bottom: 10px;
  
      select.form-select {
        width: 100%;
        border-radius: 50px;
      }
    }
  }
  @media only screen and (min-width:890px) and (max-width:960px){
    .account_btn_wrapper{
      display: flex;
    }
    .selectBoxContean{
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      gap:7px
    }
  
  }
  @media only screen and (min-width:820px) and (max-width:890px){
    .account_btn_wrapper{
      display: flex;
      flex-direction: column;
      gap:7px;
    }
    .balance__white-btn,
    .balance__save-btn {
      width: 100%;
      margin:0px;
    }
  }
  @media only screen and (min-width:730px) and (max-width:820px){
    .account_btn_wrapper{
      display: flex;
      gap:7px;
    }
  }
  @media only screen and (min-width:600px) and (max-width:730px){
    .selectBoxContean{
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      gap:7px
    }
    .account_btn_wrapper{
      display: flex;
      flex-direction: column;
      gap:7px;
    }
    .balance__white-btn,
    .balance__save-btn {
      width: 100%;
      margin:0px;
    }
  }
  @media only screen and (min-width:450px) and (max-width:600px) {
     .balance__header__name{
      margin-bottom: 10px;
     }
  
    .Buttonontenar {
      display: grid;
      gap: 10px;
    }
  
    .selectBoxContean {
      justify-content: center;
    }
    .account_btn_wrapper{
      width:100%;
      display: flex;
      justify-content: space-around;
    }
    .balance__white-btn,
    .balance__save-btn {
      width: 46%;
      margin-left: 0px;
    }
  
  
    .balance {
      &__header {
        padding: 18px 10px;
        display: block;
      }
  
      .conteanaha_data_ketete {
        width: 100% !important;
      }
  
      &__table {
        margin: 18px 15px;
      }
  
      &__card {
        margin: 20px 0px;
        width: 100%;
        margin-left: 0px !important;
      }
  
      &__search-row {
        display: block;
        margin: 0 22px;
  
        .dflex {
          display: block;
        }
      }
  
      &__filter-wrapper {
        &.input {
          width: 100%;
          margin-bottom: 10px;
        }
      }
  
      .react-datepicker-wrapper {
        width: 100%;
        margin-bottom: 10px;
      }
  
      .react-datepicker__input-container {
        width: 100%;
  
        input {
          width: 100%;
        }
      }
    }
  
  
  
  }
  
  @media only screen and (max-width:450px) {
    .balance__header__name{
      margin-bottom: 10px;
     }
    .classaButtondata {
      display: block;
    }
  
    .Buttonontenar {
      display: grid;
      gap: 10px;
    }
  
    .selectBoxContean {
      justify-content: center;
    }
  
    .balance__white-btn,
    .balance__save-btn {
      width: 100%;
      margin-left: 0px;
      margin:7px 0px 7px 0px;
    }
  
    .main_div_balance_data {
      display: block !important;
    }
  
    .tebaldateformette {
      min-width: unset;
    }
  
    .balance__card {
      margin-left: 0;
    }
  
    .select_main_contean {
      width: 100%;
      display: block;
    }
  
    .balance__card_TWO {
      margin: 20px 0px;
      width: 100%;
      margin-left: 0 !important;
    }
  
    #select_up_div {
      width: 100% !important;
      padding: 0px !important;
    }
  
    .card_select_data {
      display: block;
      align-items: center;
      justify-content: space-between;
      margin: 15px 22px;
    }
  
    .balance {
      &__header {
        padding: 18px 10px;
        display: block;
      }
  
      .conteanaha_data_ketete {
        width: 100% !important;
      }
  
      &__table {
        margin: 18px 15px;
      }
  
      &__card {
        margin: 20px 0px;
        width: 100%;
        margin-left: 0px !important;
      }
  
      &__search-row {
        display: block;
        margin: 0 22px;
  
        .dflex {
          display: block;
        }
      }
  
      &__filter-wrapper {
        &.input {
          width: 100%;
          margin-bottom: 10px;
        }
      }
  
      .react-datepicker-wrapper {
        width: 100%;
        margin-bottom: 10px;
      }
  
      .react-datepicker__input-container {
        width: 100%;
  
        input {
          width: 100%;
        }
      }
    }
  
    .balance_cat {
      margin: 0 0px;
      margin-bottom: 10px;
  
      select.form-select {
        width: 100%;
        border-radius: 50px;
      }
    }
  }
  
  .contenar_the_data {
    border-radius: 10px;
    box-shadow: 0 3px 11px hsla(0, 2%, 61%, 0.25);
    margin-bottom: 20px;
    padding: 0px 10px;
  }
  // Media Queries for filters**********
  @media only screen and (min-width:1220px) and (max-width:1880px){
    .balance {
      .conteanaha_data_ketete {
        margin-right: 5px;
        margin-left:5px;
      }
    }
  }
  @media only screen and (min-width:930px) and (max-width:1220px){
    .balance {
      .account_filters_wrapper{
        display: flex;
        justify-content: space-between !important;
        width:100% !important;
      }
      .conteanaha_data_ketete {
        width:20% !important;
        display: inline-block!important;
        margin-right: 5px;
        margin-left:5px;
      }
  
      &__table {
        margin: 18px 15px;
      }
  
      &__card {
        margin: 20px 0px;
        width: 100%;
        margin-left: 0px !important;
      }
  
      &__search-row {
        display: block;
        margin: 0 22px;
  
        .dflex {
          display: block;
        }
      }
  
      &__filter-wrapper {
        &.input {
          width: 100%;
          margin-bottom: 10px;
        }
      }
  
      .react-datepicker-wrapper {
        width: 100%;
        margin-bottom: 10px;
      }
  
      .react-datepicker__input-container {
        width: 100%;
  
        input {
          width: 100%;
        }
      }
    }
  
    .balance_cat {
      margin: 0 0px;
      margin-bottom: 10px;
  
      select.form-select {
        width: 100%;
        border-radius: 50px;
      }
    }
   
    
  }
  @media only screen and (min-width:600px) and (max-width:930px){
    .balance {
      .account_filters_wrapper{
        display: flex;
        justify-content: space-between !important;
        width:100% !important;
        gap:10px !important;
      }
      .conteanaha_data_ketete {
        width:30% !important;
        display: inline-block!important;
        margin-right: 5px;
        margin-left:5px;
      }
  
      &__table {
        margin: 18px 15px;
      }
  
      &__card {
        margin: 20px 0px;
        width: 100%;
        margin-left: 0px !important;
      }
  
      &__search-row {
        display: block;
        margin: 0 22px;
  
        .dflex {
          display: block;
        }
      }
  
      &__filter-wrapper {
        &.input {
          width: 100%;
          margin-bottom: 10px;
        }
      }
  
      .react-datepicker-wrapper {
        width: 100%;
        margin-bottom: 10px;
      }
  
      .react-datepicker__input-container {
        width: 100%;
  
        input {
          width: 100%;
        }
      }
    }
  
    .balance_cat {
      margin: 0 0px;
      margin-bottom: 10px;
  
      select.form-select {
        width: 100%;
        border-radius: 50px;
      }
    }
   
    
  }
