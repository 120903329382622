.addinvitelisttwo {
    background: #FFFFFF;
    box-shadow: 0px 3px 11px rgba(158, 155, 155, 0.25);
    border-radius: 10px;
    margin: 0 auto;
    width: 900px;
    padding: 34px 50px;
}

.addinvitetext {
    font-size: 25px;
    color: #000;
    font-weight: 600;
}

.addtestdiscer {
    text-align: center;
    padding-bottom: 20px;
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 13px;
    color: #282828;
}

.addtestdiscertwo {
    text-align: center;
    padding-bottom: 20px;
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 13px;
    color: #8C8C8C;
}

.text-field {
    border: 1px solid #DDDDDD;
    border-radius: 5px;
    width: 95%;
    height: 48px;
    padding: 0px 8px;
    outline: none;
    font-weight: 400;
    font-size: 11px;
    line-height: 24px;
    color: #3A3A3A;
    margin: 0px 10px 0px 0px;
    background: #F3F3F3;
}

.addnewowenwr {
    display: block !important;
    border: 1px solid #DDDDDD !important;
    border-radius: 4px !important;
    margin: 5px 0px !important;
    background: #fff !important;
    width: 100% !important;
    padding: 8px;
}

// .addnewowenwr:hover{
//     background-color: red !important;
//     background: red !important;
// }
.newonewtext {
    color: #515151;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    /* 212.5% */
    letter-spacing: 0.4p

}

.list_li_data_logg {
    color: #515151;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 212.5% */
    letter-spacing: 0.4p
}

.addtextdiv {
    color: #282828;
    font-size: 13px;
    font-weight: 400;
    text-align: left;
}

.css-6hp17o-MuiList-root-MuiMenu-list {
    // width: 267px;
}

#addinvitebtn {
    margin-top: 35px !important;
    width: 49%;
}

#two_list_go_bank {
    margin-top: 35px !important;
    width: 49%;
}

#adduserbtn {
    // margin-top: 15px;
    // margin-left: 8px;
    margin: 12px 16px;
}

.addnewdaddaa {
    font-family: 'Poppins';
    font-style: normal;
    font-size: 12px;
    color: red;
    padding: 4px 0px 0px 12px;
}

.box_list {
    width: 100%;
}

.box_list_main {
    display: flex;
    border: 1px solid #DDDDDD;
    padding: 24px 16px;
    border-bottom: 0px solid;
}

.mainform {
    // border: 1px solid;

}

.box_list_maintwo {
    display: flex;
    border: 1px solid #DDDDDD;
    border-radius: 10px 10px 0px 0px;
}

.emaillebal {
    font-family: 'Poppins';
    font-style: normal;
    font-size: 13px;
    color: #000;
    width: 100%;
    margin-bottom: 0px;
    padding: 10px 0px 10px 20px;

}

.addbtninput {
    border: 1px solid #DDDDDD;
    border-radius: 0px 0px 10px 10px;
    // border-bottom: 0px solid;

}

.hefingtage {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 40px;
    text-align: center;
    color: #025041;
    margin-bottom: 12px;
}

.addnewcanselbtn {
    width: 100%;
    padding-top: 30px;
    text-align: center;
    padding-bottom: 20px;
}

.retanbtnadad {
    color: #000 !important;
    background-color: #FFFFFF !important;
    border-color: #015241 !important;
    padding: 11px 25px;
    width: 55%;
}

.addmenuitem:hover {
    background-color: transparent !important;
}

.addautosert {
    background: #F3F3F3;
    border-radius: 4px;
    height: 48px;
    border: 1px solid #DDDDDD;
    border-radius: 5px;
    width: 95%;
    margin: 0px 0px 0px 10px;
}

.addautosert fieldset {
    border: 0px solid !important;
}

.addautosert input {
    font-weight: 400;
    font-size: 11px;
    line-height: 24px;
    color: #3A3A3A;
    font-family: 'Poppins';
}

.addmaccrodnew {
    background: transparent !important;
    box-shadow: none !important;
}

.addmaccrodnew:hover {
    background-color: transparent !important;
    background: transparent !important;
}

@media only screen and (max-width: 820px) {
    .addinvitelisttwo {
        width: 90%;
    }
}

@media only screen and (max-width: 450px) {
    .addinvitelisttwo {
        width: 100% !important;
        padding: 34px 20px;
    }
}