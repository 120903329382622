.contenar_in_main {
    width: 80%;
    // margin:40px auto;
    height: 100%;
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 0px 15px 0px rgba(17, 17, 17, 0.10);
    padding: 27px 30px;
    margin: auto;

    label {
        color: #000;
        font-family: Poppins;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }

    .heding_data_main_key {
        color: #2D2C2C;
        font-family: "Poppins";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        text-align: center;
        padding-bottom: 20px;
    }

    .displya_data_fastdiv {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .fast_button_and_input {
            width: 48.5%;

            // display: flex;


            .add_button_thisss {
                font-family: "Poppins";
                color: #025041;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                border-radius: 5px;
                border: 1px solid var(--theme-neutrals-80-light-grey-2, #DDD);
                background: var(--theme-neutrals-100-white, #FFF);
                margin-left: 15px;

            }
        }

    }

    .manin_loop_collam {
        border-radius: 9px;
        border: 1px solid #DDD;
        padding: 10px 10px 28px 10px;

        .main_contean_data_inthis {
            display: flex;
            align-items: center;
            width: 50%;

            .main_div_no_text {
                color: #000;
                font-family: "Poppins";
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                padding: 0px 5px;
            }
        }

    }
}

@media only screen and (max-width: 450px) {
    .contenar_in_main {
        padding: 27px 10px;
        width: 100%;

        .displya_data_fastdiv {
            display: block;

            .fast_button_and_input {
                width: 100%;

                .add_button_thisss {
                    // padding: 0;
                    margin-left: 5px;
                    padding: 14px 9px !important;
                }
            }
        }

        .manin_loop_collam {
            .main_contean_data_inthis {
                // display:block;
                width: 100%;
            }
        }
    }

    .manin_loop_collam {
        display: block !important;
    }

    #main_input_datatatata {
        width: 100% !important;
    }

    .maihagaygajnjaauh {
        align-items: normal !important;
    }

    #id_buttan_contenar_list {
        display: block !important;
        align-items: center;
    }

    #save_conteananan_dataa {
        padding-top: 0px;

    }
}